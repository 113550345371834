import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../CSS_Files/AdminPanel/Addnewuser.css";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const EditUser = ({  user, onClose, onEditUser }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [dbAllowed, setDbAllowed] = useState(false);
  const [docspermissions, setDocsPermissions] = useState({
    docs: false,
    excel: false,
    pptx: false,
    pdfs: false,
    text: false,
  });
  const [showSublist, setShowSublist] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [numDatabases, setNumDatabases] = useState(0);
  const [numDocuments, setNumDocuments] = useState(0);
  const [numQueries, setNumQueries] = useState(0);
  
  const [queryLeft, setQueryLeft] = useState();
  const [dbLeft, setDbLeft] = useState();
  const [docLeft, setDocLeft] = useState();
  
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      setUsername(user.name);
      setEmail(user.email);
    }
  }, [user]);

  const handleDatabaseChange = (event) => {
    setDbAllowed(event.target.checked);
  };

  const handlePermissionChange = (event) => {
    const { id, checked } = event.target;
    if (id === "documents") {
      setShowSublist(checked);
    }
    setDocsPermissions((prevPermissions) => ({
      ...prevPermissions,
      [id]: checked,
    }));
  };

  const handleNumDatabasesChange = (event) => {
    setNumDatabases(event.target.value);
  };

  const handleNumDocumentsChange = (event) => {
    setNumDocuments(event.target.value);
  };

  const handleNumQueriesChange = (event) => {
    setNumQueries(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${host}/auth/getAdmin`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCompanyName(res.data.company);
        setQueryLeft(res.data.queryLimit - res.data.queryAlloted);
        setDbLeft(res.data.dbLimit - res.data.dbAlloted);
        setDocLeft(res.data.docLimit - res.data.docAlloted);
      })
      .catch((err) => console.log(err));
  }, []);
  
  useEffect(() => {
    console.log("dbLeft:", dbLeft);
    console.log("docLeft:", docLeft);
    console.log("queryLeft:", queryLeft);
  }, [dbLeft, docLeft, queryLeft]);
  
  const handleAddUser = async (event) => {
    event.preventDefault();
    const newErrors = {};
  
    if (numQueries > queryLeft) {
      newErrors.numQueries = `Cannot exceed ${queryLeft} queries`;
    }
  
    if (dbAllowed && numDatabases > dbLeft) {
      newErrors.numDatabases = `Cannot exceed ${dbLeft} databases`;
    }
  
    if (showSublist && numDocuments > docLeft) {
      newErrors.numDocuments = `Cannot exceed ${docLeft} documents`;
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    const docAllowed = [
      docspermissions.pptx ? 0 : -1,
      docspermissions.docs ? 1 : -1,
      docspermissions.excel ? 2 : -1,
      docspermissions.pdfs ? 3 : -1,
      docspermissions.text ? 4 : -1,
    ].filter((item) => item !== -1);
  
    try {
      const response = await fetch(`${host}/admin/editUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          name: username,
          email,
          queryLimit: numQueries,
          dbAllowed,
          docAllowed,
          docLimit: numDocuments,
          dbLimit: numDatabases,
          enterpriseuser: true,
          company: companyName,
        }),
      });
  
      const output = await response.json();
      console.log(output);
      onClose();
      onEditUser(email);
    } catch (error) {
      console.log("Error adding user:", error);
    }
  };
  

  return (
    <div className="adduser-container">
      <div className="adduser-header">
        <div className="adduser-heading">Edit User Details</div>
        <div className="adduser-close-button" onClick={onClose}>
          X
        </div>
      </div>

      <form onSubmit={handleAddUser} className="adduser-form">
        <label htmlFor="username">User name</label>
        <input
          type="text"
          id="username"
          value={username}
          placeholder={username}
          style={{opacity:"0.2"}}
          readOnly 
        />
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          value={email}
          placeholder={email}
          style={{opacity:"0.2"}}
          readOnly 
        />
        <label htmlFor="numQueries">Queries Allowed</label>
        <input
          type="number"
          id="numQueries"
          value={numQueries}
          onChange={handleNumQueriesChange}
          placeholder="5"
        />
        {errors.numQueries && <div style={{color:"red"}} className="error-message">{errors.numQueries}</div>}

        <label>Permissions</label>
        <div className="adduser-permission-group-main-database">
          <div className="adduser-permission-group">
            <div>
              <input
                type="checkbox"
                id="database"
                onChange={handleDatabaseChange}
                style={{ backgroundColor: "yellow" }}
              />
            </div>
            <div>
              <img
                src="../../assets/Adminapp/databaseadduser.png"
                alt="Database"
                style={{ width: "50%" }}
              />
            </div>
            <div>
              <label htmlFor="database">Database</label>
            </div>
          </div>
          {dbAllowed && (
            <div className="adduser-permission-group">
              <label htmlFor="numDatabases">Number of Databases Allowed</label>
              <input
                type="number"
                id="numDatabases"
                value={numDatabases}
                onChange={handleNumDatabasesChange}
                placeholder="Enter number"
              />
              {errors.numDatabases && <div style={{color:"red"}}  className="error-message">{errors.numDatabases}</div>}
            </div>
          )}
        </div>

        <div className="adduser-permission-group-documents">
          <div className="adduser-permission-top">
            <div>
              <input
                type="checkbox"
                id="documents"
                onChange={handlePermissionChange}
              />
            </div>
            <div>
              <img
                src="../../assets/Adminapp/documentsadduser.png"
                alt="Documents"
                style={{ width: "50%" }}
              />
            </div>
            <div>
              <label htmlFor="documents">Documents</label>
            </div>
          </div>
          <div className="adduser-permission-bottom">
            {showSublist && (
              <div className="adduser-document-sublist">
                {["docs", "excel", "pptx", "pdf", "text"].map((type) => (
                  <div className="adduser-permission-item" key={type}>
                    <div>
                      <input
                        type="checkbox"
                        id={type}
                        onChange={handlePermissionChange}
                      />
                    </div>
                    <div>
                      <img
                        src={`../../assets/${type}.png`}
                        alt={type}
                      />
                    </div>
                    <div>
                      <label htmlFor={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {showSublist && (
            <div className="adduser-permission-group">
              <label htmlFor="numDocuments">Number of Documents Allowed</label>
              <input
                type="number"
                id="numDocuments"
                value={numDocuments}
                onChange={handleNumDocumentsChange}
                placeholder="Enter number"
              />
              {errors.numDocuments && <div style={{color:"red"}}  className="error-message">{errors.numDocuments}</div>}
            </div>
          )}
        </div>

        <div className="adduser-form-buttons">
          <button type="submit" className="adduser-add-button">
            Edit
          </button>
          <button
            type="button"
            className="adduser-cancel-button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
