import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "../../CSS_Files/UserPanel/NewChat.css";
import axios from "axios";
import FileModal from "../Common/FileViewerModal.js";
import TitleContext from "../UserPanel/TitleContext.js";
import Alert from "../Common/Alertbox.js";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const RightSection = () => {
  let navigate = useNavigate();
  const chatId = useParams().id;
  const chatContainerRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [convId, setConvId] = useState(null);
  const [inputText, setInputText] = useState("");
  const [isFirstChat, setIsFirstChat] = useState(true);
  const [prevChat, setPrevChat] = useState([]);
  const [name, setName] = useState("");
  const [botResponse, setBotResponse] = useState(null);
  const [isCopyClicked, setIsCopyClicked] = useState(false);
  const [isRegenerateClicked, setIsRegenerateClicked] = useState(false);
  const [isPinClicked, setIsPinClicked] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(true);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const defaultUserImagePath = "../../assets/Userlogo.png";
  const [userImage, setUserImage] = useState(defaultUserImagePath);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [showAlertForDB, setShowAlertForDB] = useState(false);

  const typingareabuttons = [
    { name: "Database", flag: "database" },
    { name: "Documents", flag: "rag" },
    { name: "ML Models", flag: "prediction" },
    { name: "Sheets", flag: "excel" },
  ];
  const { gettingtitle } = useContext(TitleContext);

  useEffect(() => {
    initializeChat();
    // eslint-disable-next-line
  }, [chatId]);

  useEffect(() => {
    updateStyles();
    // eslint-disable-next-line
  }, [colors]);

  useEffect(() => {
    updateButtonStates();
    scrollChatToBottom();
    // eslint-disable-next-line
  }, [prevChat]);

  const initializeChat = async () => {
    setConvId(chatId);
    setPrevChat([]);
    setIsBotTyping(false);
    setIsRegenerating(false);
    setSelectedButton("abc");
    setIsFirstChat(true);

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.get(`${host}/auth/getuser`, {
        headers: { "auth-token": token },
      });
      const fullName = response.data.name;
      const firstName = fullName.split(" ")[0];
      setName(firstName);
      setUserImage(response.data.picture || "../../assets/Userlogo.png");

      if (chatId) {
        setLoading(true);
        const chatResponse = await fetchChatHistory(chatId);
        setLoading(false);
        setPrevChat(chatResponse);
        setSelectedButton(
          chatResponse[chatResponse.length - 1]?.currentFlowFlag
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to fetch chat history
  const fetchChatHistory = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axios.get(`${host}/chat/getConv/${chatId}`, {
        headers: {
          "auth-token": token,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateStyles = () => {
    const root = document.documentElement;
    root.style.setProperty("--primary-bg-dark", colors.primaryBgDark);
    root.style.setProperty("--primary-bg-files", colors.primaryBgfiles);
    root.style.setProperty("--text-color", colors.textcol);
    root.style.setProperty("--chatbackground", colors.chatbg);
    root.style.setProperty("--chatbox", colors.box);
  };

  const updateButtonStates = () => {
    setIsCopyClicked(new Array(prevChat.length).fill(false));
    setIsRegenerateClicked(new Array(prevChat.length).fill(false));
    setIsPinClicked(new Array(prevChat.length).fill(false));
  };

  //To handle Pin functionality
  const handlePinClick = async (msg, index) => {
    const updatedPins = [...isPinClicked];
    updatedPins[index] = !updatedPins[index];
    setIsPinClicked(updatedPins);
    console.log("Pinned click at ", { index, msg });

    try {
      const response = await axios.put(
        `${host}/chat/unpin`,
        { msg },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      console.log("Server response:", response);

      const chatResponse = await fetchChatHistory(chatId);
      setPrevChat(chatResponse);
      console.log("Message Pinned successfully");
    } catch (err) {
      console.error("Error in handlePinClick:", err);
    }
  };

  //To handle copy functionality
  const handleCopyClick = (message, index) => {
    const updatedCopy = new Array(prevChat.length).fill(false);
    updatedCopy[index] = !isCopyClicked[index];
    setIsCopyClicked(updatedCopy);

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(message)
        .then(() => {
          console.log("Message copied to clipboard");
        })
        .catch((error) => {
          console.error("Failed to copy:", error);
          alert("Failed to copy text. ");
        });
    } else {
      console.error("Clipboard API is not available");
      alert("Clipboard API is not available");
    }
  };

  //To handle Regenerate Functionality
  const handleRegenerateClick = async (index) => {
    if (isRegenerating || isBotTyping) {
      alert("Message is already generating. Please wait for some time");
      return;
    }

    setIsRegenerating(true);
    const updatedRegenerate = [...isRegenerateClicked];
    updatedRegenerate[index] = !updatedRegenerate[index];
    setIsRegenerateClicked(updatedRegenerate);

    const updatedPrevChat = [...prevChat];

    if (index === updatedPrevChat.length - 1) {
      updatedPrevChat.pop();
      setPrevChat(updatedPrevChat);
    } else {
      const message = prevChat[index - 1];
      updatedPrevChat.push(message);
      setPrevChat(updatedPrevChat);
    }

    try {
      const response = await fetch(`${host}/chat/regenerate/${convId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ idx: index }),
      });

      const result = await response.json();

      if (!result) {
        throw new Error("Empty response received.");
      }

      const botResponse = result.response;
      setBotResponse(botResponse);

      setPrevChat((prevChat) => [...prevChat, botResponse]);

      setIsRegenerating(false);

      await fetch(`${host}/chat/setTitle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ convId: result.convId }),
      });

      gettingtitle(result.convId);
    } catch (error) {
      console.error("Error regenerating conversation:", error);
      setIsRegenerating(false);
    }
  };

  const handleOutgoingChat = async () => {
    try {
      if (isBotTyping || isUserTyping) return;
  
      const userText = inputText.trim();
      if (!userText) return;
  
      setIsUserTyping(true);
      setInputText("");
  
      if (isFirstChat) setIsFirstChat(false);
  
      const newChat = {
        content: userText,
        content_type: "text",
        currentFlowFlag: selectedButton,
      };
  
      setPrevChat((prevChat) => [...prevChat, newChat]);
      setIsBotTyping(true);
  
      const { data: userData } = await axios.get(`${host}/auth/getuser`, {
        headers: { "auth-token": token },
      });
  
      if (userData.queryAllowed === false) {
        handleQueryNotAllowed();
        return;
      }
  
      const chatResponse = await fetch(`${host}/chat/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          msg: userText,
          currentFlowFlag: selectedButton,
          convId,
        }),
      });
  
      if (!chatResponse.ok) {
        const message = await chatResponse.text();
        alert(message);
        console.log(message);
        return;
      }
  
      const result = await chatResponse.json();
      if (!result) throw new Error("Empty response received.");
  
      const botResponse = result.response;
      setIsBotTyping(false);
      setPrevChat((prevChat) => [...prevChat, botResponse]);
      setBotResponse(botResponse);
  
      if (!chatId) navigate(`/newchat/${result.convId}`);

      await fetch(`${host}/chat/setTitle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ convId: result.convId }),
      });
  
      gettingtitle(result.convId);

      if (prevChat.length > 1) {
        const chatHistory = await fetchChatHistory(chatId);
        setPrevChat(chatHistory);
        console.log("prevChat: ", chatHistory);
      }
    } catch (error) {
      console.error("Error in handleOutgoingChat:", error);
    } finally {
      setIsUserTyping(false);
    }
  };
  

  const handleQueryNotAllowed = () => {
    console.log("is bot typing: ", isBotTyping);
    console.log("is regenerating: ", isRegenerating);
    console.log("prev chat before pop:", prevChat);  
    setIsBotTyping(false);
    setIsRegenerating(false);
    prevChat.pop();  
    console.log("prevChat after pop:", prevChat);
    setShowAlertForDB(true);
  };
  

  const getFileUrl = async (csvPath) => {
    console.log("csv in getfileurl", csvPath);
    console.log("complete csv path in  store api : ", csvPath);
    console.log(
      "csv path in  store api : ",
      csvPath.split("/").slice(1).join("/")
    );

    const csv_path = csvPath.split("/").slice(1).join("/");
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`${host}/get-file-url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ path: csv_path }),
      });

      return response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handlefileviewer = async (message) => {
    console.log("message : ", message);
    const fileUrl = await getFileUrl(message.csv_path);
    setShowModal(true);
    setFileUrl(fileUrl.url);
    setFileExtension(fileUrl.extension);
  };

  const handleimageviewer = async (message) => {
    console.log("message in handle image viewer : ", message);
    setShowModal(true);
    setFileUrl(message.content);
    setFileExtension(".png");
  };

  const closeModal = () => {
    setShowModal(false);
    setFileUrl(null);
    setFileExtension(null);
  };

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const inputTextHandler = (e) => {
    setInputText(e.target.value);
    setIsUserTyping(e.target.value.trim() === "");
  };

  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/login");
  }

  const renderTypingAnimation = () =>
    (isBotTyping || isRegenerating) && (
      <div className="chat-content incoming">
        <div className="chat-details">
          <img
            src="../../assets/Logo-Light-transparent.png"
            alt="chatbot-img"
          />
          <div className="typing-animation">
            {[0.2, 0.3, 0.4].map((delay, index) => (
              <div
                key={index}
                className="typing-dot"
                style={{ "--delay": `${delay}s` }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    );

  const renderChatResponse = (response) => {
    return response.content_type === "text" ? (
      <p
        className="leading-loose p-content"
        style={{ whiteSpace: "break-spaces" }}
      >
        {response.content}
      </p>
    ) : (
      <img
        src={`data:image/png;base64,${response.content}`}
        alt="Response"
        className="response-image"
        onClick={() => handleimageviewer(response)}
      />
    );
  };

  const renderChatMessages = () => {
    return prevChat.map((message, index) => {
      const isOutgoing = index % 2 === 0;
      const chatClass = isOutgoing ? "outgoing" : "incoming";
      const userDetailsClass = isOutgoing ? "incom" : "user-detailss";
      const imgSrc = isOutgoing
        ? userImage
        : "../../assets/Logo-Light-transparent.png";
      const imgAlt = isOutgoing ? "user-img" : "chatbot-img";
      const imgClass = isOutgoing ? "userimageeee" : "botlogo";

      return (
        <div className="chat" key={index}>
          <div className={`chat-content ${chatClass}`}>
            <div className={`chat-details ${userDetailsClass}`}>
              {!isOutgoing && (
                <>
                  <img src={imgSrc} alt={imgAlt} className={imgClass} />
                  {renderChatResponse(message)}
                </>
              )}
              {isOutgoing && (
                <>
                  <p
                    className="usermessagesss"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {message.content}
                  </p>
                  <img src={imgSrc} alt={imgAlt} className={imgClass} />
                </>
              )}
            </div>
            <div className="features">
              {isOutgoing && (
                <span
                  className={`material-symbols-outlined ${
                    isCopyClicked[index] ? "copy-clicked" : ""
                  }`}
                  onClick={() => handleCopyClick(message.content, index)}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "60px",
                    marginTop: "10px",
                  }}
                >
                  <img src="../../assets/copy.png" alt="copy" />
                </span>
              )}

              {!isOutgoing && (
                <>
                  <span
                    className={`material-symbols-outlined ${
                      isCopyClicked[index] ? "copy-clicked" : ""
                    }`}
                    onClick={() => handleCopyClick(message.content, index)}
                  >
                    <img src="../../assets/copy.png" alt="copy" />
                  </span>
                  <span
                    className={`material-symbols-outlined ${
                      isRegenerateClicked[index] ? "Regenerate-clicked" : ""
                    }`}
                    onClick={() => handleRegenerateClick(index)}
                  >
                    <img src="../../assets/Regenerate.png" alt="regenerate" />
                  </span>
                  <span
                    className={`material-symbols-outlined ${
                      isPinClicked[index] || message.pin ? "pin-clicked" : ""
                    }`}
                    onClick={() => handlePinClick(message, index)}
                  >
                    <img src="../../assets/pin.png" alt="pin" />
                  </span>
                  {message.content_type !== "text" && (
                    <span
                      className="material-symbols-outlined"
                      onClick={() => handleimageviewer(message, index)}
                    >
                      <img
                        src="../../assets/Eye.png"
                        alt="excel"
                        style={{ width: "60%" }}
                      />
                    </span>
                  )}

                  {message.csv_path && (
                    <span
                      className="material-symbols-outlined"
                      onClick={() => handlefileviewer(message, index)}
                    >
                      <img
                        src="../../assets/excelg.png"
                        alt="excel"
                        style={{ width: "17%" }}
                      />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderUpperSection = () => {
    if (!chatId && isFirstChat && prevChat.length === 0) {
      return (
        <div className="upper-section">
          <div className="main-heading">
            <p>"In God we trust. All others must bring data."</p>
          </div>
          <div className="sub-heading">
            <p className="bold">{name}</p>
          </div>
          <div className="tagline">
            <p className="bold">Enjoy talking to your data today!</p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="newchat-container">
      <div className="newchat-leftcontainer"></div>

      <div className="newchat-middlecontainer">
        {renderUpperSection()}

        <div className="chat-container" ref={chatContainerRef}>
          {renderChatMessages()}
          {isLoading && <div className="loading-indicator"></div>}
          {renderTypingAnimation()}
        </div>

        <div className="typing-textarea">
          <textarea
            id="chat-input"
            placeholder="Get insights from your enterprise data..."
            required
            onChange={inputTextHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleOutgoingChat();
              }
            }}
            value={inputText}
          ></textarea>
          <div className="buttons">
            <div className="description_buttons">
              {typingareabuttons.map(({ name, flag }) => {
                const isSelected =
                  selectedButton &&
                  (selectedButton === name ||
                    selectedButton.toLowerCase() === flag);
                return (
                  <div
                    key={flag}
                    className={`${flag} ${
                      botResponse?.currentFlowFlag === flag ? "active" : ""
                    } ${isSelected ? "selected" : ""}`}
                    onClick={() => setSelectedButton(isSelected ? "" : name)}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
            <div className="upload-button">
              <img
                id="send-btn"
                alt="sendbutton"
                onClick={() => {
                  if (!isBotTyping && !isUserTyping) {
                    handleOutgoingChat();
                  }
                }}
                src={
                  isBotTyping || isUserTyping
                    ? "../../assets/arrow.png"
                    : "../../assets/coloured-arrow.png"
                }
                width="40px"
                height="40px"
                className={isBotTyping || isUserTyping ? "disabled" : ""}
                style={{
                  cursor:
                    isBotTyping || isUserTyping ? "not-allowed" : "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="newchat-rightcontainer"></div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {showModal && fileUrl && (
        <FileModal
          showModal={showModal}
          closeModal={closeModal}
          fileUrl={fileUrl}
          fileExtension={fileExtension}
        />
      )}
      {showAlertForDB && (
        <Alert
          message="Your token limit is exceeded! Please contact the organization"
          type="error"
          onClose={() => setShowAlertForDB(false)}
        />
      )}
    </div>
  );
};

export default RightSection;
