import React, { useState, useEffect } from "react";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import AddNewUser from "../AdminPanel/AddNewUser";
import EditUser from "../AdminPanel/EditUser.js";
import InvitationSuccessful from "../AdminPanel/InvitationDialogBox.js";
import Pagination from "../Common/Pagination";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";
import "../../CSS_Files/AdminPanel/Users.css";
import Alert from "../Common/Alertbox.js"
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const User= () => {
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isInvitationSuccessful, setIsInvitationSuccessful] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentEmail, setPaymentEmail] = useState(""); 
  const [showAlertForEdit, setShowAlertForEdit] = useState(false);
  const usersPerPage = 4;

  const fetchuserdetails = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }
  
      const response = await axios.get(`${host}/admin/getUsers`, {
        headers: {
          "auth-token": token,
        },
      });
  
      return response.data.users;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  

  useEffect(() => {
    axios
      .get(`${host}/admin/getUsers`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data.users || [];
        setUserData(data);
        setFilteredUserData(data); 
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredData = userData.filter((user) =>
      user.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUserData(filteredData);
    setCurrentPage(1); 
  };

  const handleSearch = () => {
    const filteredData = userData.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUserData(filteredData);
    setCurrentPage(1);
  };

  const renderPermission = (permissions) => {
    return permissions.map((permission, index) => {
      switch (permission) {
        case 0:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/pptx.png" alt="pptx"></img>
                </div>
                <div>
                  <p>Pptx</p>
                </div>
              </div>
            </div>
          );
        case 1:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/docs.png" alt="docs"></img>
                </div>
                <div>
                  <p>Docs</p>
                </div>
              </div>
            </div>
          );
        case 2:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/excel.png" alt="excel"></img>
                </div>
                <div>
                  <p>Excel</p>
                </div>
              </div>
            </div>
          );
        case 3:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/pdf.png" alt="pdf"></img>
                </div>
                <div>
                  <p>Pdf</p>
                </div>
              </div>
            </div>
          );
        case 4:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/text.png" alt="pdf"></img>
                </div>
                <div>
                  <p>text</p>
                </div>
              </div>
            </div>
          );
        case 5:
          return (
            <div key={index} className="permission-message">
              <div className="UserPermission-fields">
                <div>
                  <img src="../../assets/pdf.png" alt="pdf"></img>
                </div>
                <div>
                  <p>Music</p>
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  };

  const handleAddUserOpen = () => {
    setIsAddUserOpen(true);
  };

  const handleAddUserClose = () => {
    setIsAddUserOpen(false);
  };

  const handleEditUserClose = () => {
    setIsEditUserOpen(false);
  };

  const handleInvitationSuccess = (email) => {
    setIsAddUserOpen(false);
    setPaymentEmail(email);
    setIsInvitationSuccessful(true);
    fetchuserdetails().then((data) => {
      if (data) {
        setUserData(data);
        setFilteredUserData(data);
        setLoading(false);
      }
    });
  };

  const handleEditSucess= () => {
    setIsEditUserOpen(false);
    setShowAlertForEdit(true);
  };
  
  const handleCloseInvitation = () => {
    setIsInvitationSuccessful(false);
    fetchuserdetails();
  };

  const handleClickDelete = (user) => {
    setUserToDelete(user);
    setOpenDeletePopup(true);
  };

  const handleClickEdit = (user) => {
    setUserToEdit(user);
    setOpenEditPopup(true);
  };


  const deleteDB = async (user) => {
    setIsDeletingUser(true);
    try {
      await axios.delete(`${host}/admin/deleteUser/${user._id}`, {
        headers: { "auth-token": localStorage.getItem("token") },
      });
      setUserData((prevData) =>
        prevData.filter((u) => u._id !== user._id)
      );
      setFilteredUserData((prevData) =>
        prevData.filter((u) => u._id !== user._id)
      );
      setOpenDeletePopup(false);
    } catch (err) {
      console.error("Error deleting user:", err);
    } finally {
      setIsDeletingUser(false);
      setUserToDelete(null);
    }
  };

  const EditDB = async (user) => {
    setOpenEditPopup(false);
    setIsEditUserOpen(true);
  }

  const closeDeleteDialog = () => {
    setOpenDeletePopup(false);
  };

  const closeEditDialog = () => {
    setOpenEditPopup(false);
  };

  const confirmDeleteQuery = () => {
    deleteDB(userToDelete);
  };

  const confirmEditQuery = () => {
    EditDB(userToEdit);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUserData.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  return (
    <div className="AdminPanel_User_container">
      <div className="AdminPanel_User_leftcontainer"></div>
      <div className="AdminPanel_User_middlecontainer">
        <div className="AdminPanel_middlecontainer_user_main">
          <div className="AdminPanel_middlecontainer_user_leftside">Users</div>
          <div className="AdminPanel_middlecontainer_user_rightside">
            <div className="AdminPanel_search_container">
              <input
                type="text"
                placeholder="Search User"
                id="search-input"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button id="search-button" onClick={handleSearch}>
                Search
              </button>
            </div>
            <div
              className="AdminPanel_middlecontainer_user_adduser"
              onClick={handleAddUserOpen}
            >
              <div>
                <img
                  src="../../assets/Adminapp/adduser.png"
                  alt="adduser"
                  style={{ width: "15px" }}
                />
              </div>
              <div>Add New User</div>
            </div>
          </div>
        </div>
        <div className="AdminPanel_bottomcontainer_user">
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Query Used</th>
                <th>Permissions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.queryCount}</td>
                  <td>
                    {user.docAllowed ? (
                      <div className="adminpanel-users-permission">
                        {renderPermission(user.docAllowed)}
                        {user.dbAllowed && (
                          <div className="UserPermission-fields">
                            <div>
                              <img
                                src="../../assets/Adminapp/databaseselected.png"
                                alt="pdf"
                              />
                            </div>
                            <div>
                              <p>Database</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <div className="editicons">
                      <button className="action-btn"  onClick={() => handleClickEdit(user)}>
                        <img
                          src="../../assets/Edit.png"
                          alt="edit"
                          className="mr-5 cursor-pointer "
                        />
                      </button>
                      <button
                        className="action-btn"
                        onClick={() => handleClickDelete(user)}
                      >
                        {" "}
                        <img
                          src="../../assets/Delete.png"
                          alt="del "
                          className="mr-3 cursor-pointer"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {filteredUserData.length > usersPerPage && (
          <div className="pages">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredUserData.length / usersPerPage)}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
      <div className="AdminPanel_User_rightcontainer"></div>

      {isAddUserOpen && (
        <AddNewUser
          onClose={handleAddUserClose}
          onAddUser={handleInvitationSuccess}
        />
      )}
      {isEditUserOpen && (
        <EditUser
          user={userToEdit}  
          onClose={handleEditUserClose}
          onEditUser={handleEditSucess}
        />
      )}
      {isInvitationSuccessful && (
        <InvitationSuccessful email={paymentEmail} onClose={handleCloseInvitation} />
      )}
      {openDeletePopup && (
        <ModalDelete
          open={openDeletePopup}
          handleClose={closeDeleteDialog}
          func={confirmDeleteQuery}
          text_mod="Are you sure you want to delete this User?"
        />
      )}
      {openEditPopup && (
        <ModalDelete
          open={openEditPopup}
          handleClose={closeEditDialog}
          func={confirmEditQuery}
          text_mod="Are you sure you want to Edit the details of the User?"
        />
      )}
      {isDeletingUser && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
       {showAlertForEdit && (
            <Alert
              message="Your Changes are Saved successfully.. Thank you!!"
              type="error"
              onClose={() => setShowAlertForEdit(false)}
            />
          )}
    </div>
  );
};

export default User;
