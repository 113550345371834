// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e1ecf5;
  text-align: center;
  color: black;
}

.email-success_img {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.email-h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.email-button {
  font-size: 20px;
}

.email-ancor {
  text-decoration: none;
  color: inherit;
  color: blue;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/UserPanel/emailverify.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".email-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  background-color: #e1ecf5;\r\n  text-align: center;\r\n  color: black;\r\n}\r\n\r\n.email-success_img {\r\n  width: 150px;\r\n  height: 150px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.email-h1 {\r\n  font-size: 28px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.email-button {\r\n  font-size: 20px;\r\n}\r\n\r\n.email-ancor {\r\n  text-decoration: none;\r\n  color: inherit;\r\n  color: blue;\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
