import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../CSS_Files/UserPanel/signup.css";
import { useGoogleLogin } from "@react-oauth/google";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Signup = (props) => {
  // State to manage form input values
  let navigate = useNavigate();
  const [cred, setCred] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  // State to manage password match error
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  // Handle input field changes
  const onChange = (e) => {
    e.preventDefault();
    setCred({ ...cred, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (cred.password !== cred.cpassword) {
      setPasswordMatchError(true);
      props.showAlert("Passwords didn't match", "danger");
      return;
    }

    const url = `${host}/auth/createuser`;
    const { name, email, password } = cred;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password }),
      });
      const output = await response.json();

      if (output.success) {
        localStorage.setItem("token", output.authToken);
        alert("your account is created successfully !!Please check your mail to verify the details")
        navigate("/login");
      } else {
        props.showAlert("Invalid credentials", "danger");
      }

      console.log(output);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Google sign-up handler
  const signup = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        console.log("Access token -> ", response.access_token);

        const resp = await fetch(`${host}/auth/googleSignup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ access_token: response.access_token }),
        });

        const json = await resp.json();
        console.log(json);

        if (json.success) {
          localStorage.setItem("token", json.authToken);
          // alert(
          //   "Account created successfully! But you're not an authorised user for this platform. Please reach out to your admin"
          // );
          // Navigate to a different page if needed
          navigate('/emptychat');
        } else {
          props.showAlert("Invalid credentials", "danger");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  return (
    <div className="signup-page-sp">
      <div className="card login-box-sp">
        <div className="items-center-sp self-center-sp">
          <img className="" alt="logo-sp" src="../../assets/Logo-Light.png"></img>
        </div>
        <div className="card-body-sp">
          <form onSubmit={handleSubmit}>
            <div className="no-account-sp mb-2-sp">
              <div className="dont-account-sp">Already have an account?</div>
              <div className="sign-up-sp">
                <Link id="signup" to="/login">
                  Sign IN
                </Link>
              </div>
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="name" className="text-black-sp">
                Name
              </label>
              <input
                type="text"
                className="form-control-sp"
                id="name"
                name="name"
                value={cred.name}
                placeholder="Enter your name"
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="email" className="text-black-sp">
                Email address
              </label>
              <input
                type="email"
                className="form-control-sp"
                id="email"
                name="email"
                placeholder="Enter your email"
                aria-describedby="emailHelp"
                value={cred.email}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="password" className="text-black-sp">
                Password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="password"
                name="password"
                placeholder="Enter password"
                value={cred.password}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-3-sp inputtt-sp">
              <label htmlFor="cpassword" className="text-black-sp">
                Confirm password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="cpassword"
                name="cpassword"
                placeholder="Confirm password"
                value={cred.cpassword}
                onChange={onChange}
                minLength={5}
                required
              />
              {passwordMatchError && (
                <div className="text-red-500-sp text-sm-sp mb-1-sp">
                  Passwords do not match
                </div>
              )}
            </div>
            <button type="submit" className="bt1-sp  rounded-lg-sp">
              Sign Up &rarr;
            </button>
            <div className="border-with-text-signup-sp">
              <div className="border-line-sp"></div>
              <div className="text-between-sp">Or sign up with</div>
              <div className="border-line-sp"></div>
            </div>

            <div className="buttons-row-signup-sp">
              <button className="btn_alt-sp" onClick={() => signup()}>
                <div className="btns-sp">
                  <img
                    className="btnimg-sp google-sp"
                    src={"../../assets/Google.png"}
                    alt="Google"
                  />
                  <div className="btntext-sp text-black-sp">Google</div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
