import React, { useState, useEffect } from "react";
import "../../CSS_Files/AdminPanel/AdminTopBar.css";
import Dropdown from "../Common/DropDown.jsx";
import axios from "axios";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const TopBar = () => {
  const [name, setName] = useState("Profile");
  const [email, setEmail] = useState("email@gmail.com");
  const defaultUserImagePath = "../../assets/Userlogo.png";
  const [userImage, setUserImage] = useState(defaultUserImagePath);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`${host}/auth/getAdmin`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setName(res.data.name);
        setEmail(res.data.email);
        if (res.data.picture) {
          setUserImage(res.data.picture);
        } else {
          setUserImage(defaultUserImagePath);
        }
      })
      .catch((err) => console.log(err));
      // eslint-disable-next-line 
  }, []);

  const toggleDropdown = () => {
    console.log("Toggling dropdown visibility");
    setDropdownOpen(!dropdownOpen);
  };


  return (
    <div className="AdminPanel_header">
      <div>{""}</div>
      <div className="AdminPanel_account_details">
        <div className="AdminPanel_account_image">
          <img alt="profile-user" src={userImage} />
        </div>

        <div className="AdminPanel_account_name">
          <div>{name}</div>
          <div className="AdminPanel_account_email">{email}</div>
        </div>

        <div className="AdminPanel_header_ddown" onClick={toggleDropdown}>
          <img alt="dropdown-icon" src="../../assets/Adminapp/dropdown.png" />
        </div>

        <div
          className="ddown"
          style={{
            display: dropdownOpen ? "block" : "none", 
          }}
        >
          <Dropdown className="absolute" />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
