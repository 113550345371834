import React, { useState } from "react";
import "../../CSS_Files/AdminPanel/Orderinfo.css";
import PaymentSuccessful from "./PaymentSuccessful.js";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const OrderInfo = () => {
  const [queries, setQueries] = useState("");
  const [databases, setDatabases] = useState("");
  const [documents, setDocuments] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [country, setCountry] = useState("");
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const handleQueriesChange = (event) => {
    setQueries(event.target.value);
  };

  const handleDatabaseChange = (event) => {
    setDatabases(event.target.value);
  };

  const handleDocumentsChange = (event) => {
    setDocuments(event.target.value);
  }

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCardHolderNameChange = (event) => {
    setCardHolderName(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const calculateTotal = () => {
    if(queries>0){
      return queries*1 ;
    }else{
      return 0;
    }
      
  };

  const handlePayment = async (event) => {
    event.preventDefault();
    const url = `${host}/admin/upgrade`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: queries,
      }),
    });
    const output = await response.json();
    setShowPaymentSuccess(true);
    console.log(output);
  };

  const closeModal = () => {
    setShowPaymentSuccess(false);
  };

  return (
    <div className="orderinfo-container">
      <div className="orderinfo-left-container"></div>
      <div className="orderinfo-middle-container">
        <p className="orderinfo-heading">Order Information</p>
        <div className="orderinfo-panel">
          <div className="orderinfo-leftside">
            <div className="orderinfo-input-group">
              <label htmlFor="orderinfo-queries" className="orderinfo-labels">
                Select Queries:
              </label>
              <input
                type="number"
                id="orderinfo-queries"
                className="orderinfo-input"
                value={queries}
                onChange={handleQueriesChange}
                placeholder="Enter number of Queries"
              />
            </div>
            <div className="orderinfo-input-group">
              <label htmlFor="orderinfo-queries" className="orderinfo-labels">
                Select Databases:
              </label>
              <input
                type="number"
                id="orderinfo-databases"
                className="orderinfo-input"
                value={databases}
                onChange={handleDatabaseChange}
                placeholder="Enter number of Databases"
              />
            </div>
            <div className="orderinfo-input-group">
              <label htmlFor="orderinfo-queries" className="orderinfo-labels">
                Select Documents:
              </label>
              <input
                type="number"
                id="orderinfo-documents"
                className="orderinfo-input"
                value={documents}
                onChange={handleDocumentsChange}
                placeholder="Enter number of Documents"
                min="10000"
              />
            </div>
            <div className="orderinfo-total">
              <h2>Rs{" "}{calculateTotal()}</h2>
              <p>Total amount payable, including taxes.</p>
            </div>
          </div>

          <div className="orderinfo-rightside">
            <div className="orderinfo-card-details">
              <div className="orderinfo-input-group">
                <label
                  htmlFor="orderinfo-card-number"
                  className="orderinfo-labels"
                >
                  Card Details
                </label>
                <div className="orderinfo-carddd">
                  <div className="orderinfo-input-container">
                    <input
                      type="text"
                      id="orderinfo-card-number"
                      className="orderinfo-input"
                      value={cardNumber}
                      onChange={handleCardNumberChange}
                      placeholder="1204 1204 1204 1204"
                      pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}"
                      required
                    />
                    <img
                      src="../../assets/Adminapp/carddetails.png"
                      alt="card-available"
                      className="orderinfo-input-image"
                    />
                  </div>
                  <div className="orderinfo-input-container">
                    <input
                      type="text"
                      id="orderinfo-expiry-date"
                      className="orderinfo-input"
                      value={expiryDate}
                      onChange={handleExpiryDateChange}
                      placeholder="MM/YYYY"
                      pattern="[0-9]{2}/[0-9]{4}"
                      required
                    />
                    <img
                      src="../../assets/Adminapp/cardimage.png"
                      alt="card-available"
                      className="orderinfo-input-image orderinfo-input-image-bordered"
                    />
                  </div>
                </div>
              </div>

              <div className="orderinfo-input-group">
                <label
                  htmlFor="orderinfo-card-holder-name"
                  className="orderinfo-labels"
                >
                  Card Holder Name:
                </label>
                <input
                  type="text"
                  id="orderinfo-card-holder-name"
                  className="orderinfo-input"
                  value={cardHolderName}
                  onChange={handleCardHolderNameChange}
                  placeholder="John Doe"
                  required
                />
              </div>
              <div className="orderinfo-input-group">
                <label htmlFor="orderinfo-country" className="orderinfo-labels">
                  Country:
                </label>
                <select
                  id="orderinfo-country"
                  className="orderinfo-input"
                  value={country}
                  onChange={handleCountryChange}
                  required
                >
                  <option value="">Select Country</option>
                  <option value="India">India</option>
                  <option value="US">US</option>
                </select>
              </div>
              <button className="orderinfo-pay-button" onClick={handlePayment}>
                Pay ${calculateTotal()}
              </button>
            </div>
          </div>
        </div>

        <pre className="orderinfo-powered-by">
          Powered by stripe | Terms and Conditions
        </pre>
      </div>

      <div className="orderinfo-right-container"></div>

      {showPaymentSuccess && (
        <div className="orderinfo-modal">
          <PaymentSuccessful onClose={closeModal} />
        </div>
      )}
    </div>
  );
};

export default OrderInfo;
