// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-box-common {
    position: fixed;
    top: 35%;
    left: 40%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.alert-content-del{
    width: 20%;
    height: 20%;
    border-radius:18px ;
    background: #2F2F2F;
    box-shadow: "1px 1px 10px 5px #888888";
    padding: "10px";
}

`, "",{"version":3,"sources":["webpack://./src/CSS_Files/Common/Alertbox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;AACA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;AACnB","sourcesContent":[".alert-box-common {\r\n    position: fixed;\r\n    top: 35%;\r\n    left: 40%;\r\n    width: 100%;\r\n    height: 100%;\r\n    z-index: 9999;\r\n}\r\n.alert-content-del{\r\n    width: 20%;\r\n    height: 20%;\r\n    border-radius:18px ;\r\n    background: #2F2F2F;\r\n    box-shadow: \"1px 1px 10px 5px #888888\";\r\n    padding: \"10px\";\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
