import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedRoles }) => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const isAuthenticated = !!localStorage.getItem("token");
  const userRole = isAdmin ? "admin" : "user";

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.includes(userRole)) {
    window.history.back();
  }

  return children;
};

export default PrivateRoute;
