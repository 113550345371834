import { useState } from "react";
import { useParams, Link} from "react-router-dom";
import axios from "axios";
import "../../CSS_Files/AdminPanel/adminemailverify.css";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const [previousPassword, setPreviousPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 
  const { token } = useParams();
  console.log("token  : ", token);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (previousPassword === newPassword) {
      setErrorMessage("New password cannot be the same as the previous password");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    setLoading(true);
    console.log("updating details ........ ")
    try {
      const response = await axios.post(`${host}/auth/verifyuser/${token}`, {
        previousPassword,
        newPassword,
      });
      console.log("response : ", response)
      setPasswordChanged(true);
      setValidUrl(true);
      setErrorMessage(""); // Clear any previous error message
    } catch (error) {
      console.error("Failed to change password:", error);
      if (error.response && error.response.status === 400) {
        setErrorMessage("Invalid token or other error message from server.");
        setValidUrl(false);
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {validUrl ? (
        <div className="adminemail-container">
          {passwordChanged ? (
            <>
              <img
                src="../../assets/success.png"
                alt="success_img"
                className="email-success_img"
              />
              <h1 className="adminemail-h1">
                Email verified and password changed successfully
              </h1>
              <Link to="/login">
                <button className="adminemail-button">
                  To Login <span className="adminemail-ancor">click here</span>
                </button>
              </Link>
        
            </>
          ) : (
            <>
              <h1 className="adminemail-h1">
                Please change your password to verify your email
              </h1>
              <form onSubmit={handlePasswordChange} className="adminemail-form">
                <div className="adminemail-input-group">
                  <label htmlFor="previousPassword">Previous Password</label>
                  <input
                    type="password"
                    id="previousPassword"
                    value={previousPassword}
                    onChange={(e) => setPreviousPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="adminemail-input-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="adminemail-input-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                {errorMessage && <p className="adminemail-error">{errorMessage}</p>}
                <button
                  type="submit"
                  className="adminemail-button"
                  disabled={loading}
                >
                  {loading ? "Changing..." : "Change Password"}
                </button>
              </form>
            </>
          )}
        </div>
      ) : (
        <h1 className="adminemail-h1"> OOPS!! Page Not Found</h1>
      )}
    </>
  );
};

export default EmailVerify;
