import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import config from "../../config_frontend.js";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";
import "../../CSS_Files/UserPanel/favcharts.css";

const host = config.server.host[process.env.REACT_APP_HOST];

const FavouriteCharts = () => {
  //To store all the favcharts 
  const [favcharts, setFavCharts] = useState([]);
  //To open the dailog box to confirm that user wants to unpin or not               
  const [openDialog, setOpenDialog] = useState(false);
  //To unpin the chart
  const [chartToDelete, setChartToDelete] = useState(null);
  //To add Loader when user clicks on favourite charts
  const [loading, setLoading] = useState(true);
  //To view the charts on full screen 
  const [fullscreenChart, setFullscreenChart] = useState(null);

  const getFavCharts = async () => {
    try {
      let type = "image";
      const response = await axios.get(`${host}/chat/getFav/${type}`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        }
      });
      setFavCharts(response.data);
    } catch (error) {
      console.error("Error fetching favorite charts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavCharts();
  }, []);

  const openDeleteDialog = (chart) => {
    setChartToDelete(chart);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
  };

  const confirmDeleteChart = () => {
    deleteChart(chartToDelete);
    closeDeleteDialog();
  };

  const deleteChart = async (chart) => {
    setLoading(true);
    try {
      await fetch(`${host}/chat/unpin`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ msg: chart }),
      });
      getFavCharts();
    } catch (error) {
      console.error("Error unpinning chart:", error);
    } finally {
      setLoading(false);
    }
  };

  const openFullscreen = (chart, text) => {
    setFullscreenChart({
      image: `data:image/png;base64,${chart.content}`,
      text,
    });
  };

  const closeFullscreen = () => {
    setFullscreenChart(null);
  };

  return (
    <div>
      <div className="header-charts">Favourite Charts</div>
      <div className="main-container">
        {favcharts.map((chart, index) => (
          <div className="pair-container" key={index}>
            <div className="image-text">{chart.title}</div>

            <div
              className="image-div"
              onClick={() => openFullscreen(chart, chart.title)}
            >
              <img
                className="image"
                src={`data:image/png;base64,${chart.content}`}
                alt={`Chart ${index}`}
                loading="lazy"
              />
            </div>

            <button
              className="deleteButton-charts"
              onClick={() => openDeleteDialog(chart)}
            >
              <img src="../../assets/pinstraight.png" alt="Pin" />
            </button>
          </div>
        ))}
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ModalDelete
        open={openDialog}
        handleClose={closeDeleteDialog}
        func={confirmDeleteChart}
        text_mod={"Wish to unpin this chart? This action cannot be reversed"}
      />

      {fullscreenChart && (
        <Dialog
          open={!!fullscreenChart}
          onClose={closeFullscreen}
          PaperProps={{
            style: {
              borderRadius: "25px",
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogContent
            style={{ padding: 0, borderRadius: "25px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#1b2335",
                borderRadius: "25px",
                textAlign: "center",
                padding: "20px",
                opacity: "1",
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "18px",
                  color: "gray",
                }}
              >
                {fullscreenChart.text}
              </div>
              <img
                src={fullscreenChart.image}
                alt="Full screen"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                  paddingTop: "20px",
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default FavouriteCharts;
