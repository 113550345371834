module.exports = {
    client: {
        host: ["http://localhost:3000", "http://20.198.223.174.nip.io:3000", "https://qrizz-dev.algorizz.com"],
    },
    server: {
        host: ["http://localhost:8080", "http://20.198.223.174:8080", "https://qrizz-dev.algorizz.com/backend"],
    },

    gcp: {
        clientId: "755455345706-4rqjcs6ko78m927f60k8uip1urqgjqvg.apps.googleusercontent.com"
    }

}




        // host: "http://20.198.223.174.nip.io:3000",
       // host: "http://localhost:3000",
        // host: "http://localhost:3000",
        
        // host: "https://qrizz-414116.uc.r.appspot.com",

        
        // host: "http://20.198.223.174:5000",
        //host: "http://localhost:5000",
        // host: "http://localhost:8080",

        
        // host: "https://backend-dot-qrizz-414116.uc.r.appspot.com",