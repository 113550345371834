import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../CSS_Files/UserPanel/signup.css";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Signup = (props) => {
  const [cred, setCred] = useState({
    name: "",
    email: "",
    companyname: "",
    password: "",
    cpassword: "",
  });
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  let navigate = useNavigate();

  const onChange = (e) => {
    setCred({ ...cred, [e.target.name]: e.target.value });
    if (e.target.name === "password" || e.target.name === "cpassword") {
      setPasswordMatchError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cred.password !== cred.cpassword) {
      setPasswordMatchError(true);
      props.showAlert("Passwords didn't match", "danger");
      return;
    }

    const url = `${host}/auth/createadmin`;
    const { name, email, password, companyname } = cred;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password, companyname }),
    });
    const output = await response.json();
    if (output.success) {
      localStorage.setItem("token", output.authToken);
      alert(
        "your account is created successfully !!Please check your mail to verify the details"
      );
      navigate("/adminlogin");
    } else {
      props.showAlert("Invalid credentials", "danger");
    }
  };

  return (
    <div className="signup-page-sp">
      <div className="card login-box-sp">
        <div className="items-center-sp self-center-sp">
          <img
            className=""
            alt="logo-sp"
            src="../../assets/Logo-Light.png"
          ></img>
        </div>
        <div
          style={{
            fontWeight: "600",
            fontSize: "16px",
            marginLeft: "40%",
            marginTop: "24px",
          }}
        >
          Admin Sign up
        </div>

        <div className="card-body-sp">
          <form onSubmit={handleSubmit}>
            <div className="no-account-sp mb-2-sp">
              <div className="dont-account-sp">Already have an account?</div>
              <div className="sign-up-sp">
                <Link id="signup" to="/adminlogin">
                  Sign In
                </Link>
              </div>
            </div>

            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="name" className="text-black-sp">
                Name
              </label>
              <input
                type="text"
                className="form-control-sp"
                id="name"
                name="name"
                value={cred.name}
                placeholder="Enter your name"
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="email-sp" className="text-black-sp">
                Email address
              </label>
              <input
                type="email"
                className="form-control-sp"
                id="email"
                name="email"
                placeholder="Enter your email"
                aria-describedby="emailHelp"
                value={cred.email}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="companyname" className="text-black-sp">
                Company name
              </label>
              <input
                type="text"
                className="form-control-sp"
                id="companyname"
                name="companyname"
                placeholder="Enter your Company name"
                aria-describedby="emailHelp"
                value={cred.companyname}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="password" className="text-black-sp">
                Password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="password"
                name="password"
                placeholder="Enter password"
                value={cred.password}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-3-sp inputtt-sp">
              <label htmlFor="cpassword" className="text-black-sp">
                Confirm password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="cpassword"
                name="cpassword"
                placeholder="Confirm password"
                value={cred.cpassword}
                onChange={onChange}
                minLength={5}
                required
              />
              {passwordMatchError && (
                <div className="text-red-500-sp text-sm-sp mb-1-sp">
                  Passwords do not match
                </div>
              )}
            </div>
            <button type="submit" className="bt1-sp  rounded-lg-sp">
              Sign Up &rarr;
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
