import React from "react";
import "../../CSS_Files/AdminPanel/PaymentSuccessful.css";

const PaymentSuccess = ({ email, onClose }) => {

  return (
    <div className="Payment-container">
      <div className="closebutton-Payment" onClick={onClose}>
        <img
          src="../../assets/Adminapp/closebutton.png"
          alt="closebutton-invitation"
          style={{ width: "15px" }}
        ></img>
      </div>
      <div className="succesful-image-payment">
        <img
          src="../../assets/Adminapp/invitationsuccesful.png"
          alt="succesful"
          style={{ width: "20%" }}
        ></img>
      </div>

      <div className="first-heading-payment">
        Payment Processed Successfully!
      </div>
      <div className="second-heading-payment">
        {" "}
        <p>
          Additional Queries will be added to your plan.
          <p style={{ paddingLeft: "30px" }}>Thank you for choosing qRIZZ</p>
        </p>
      </div>

      <div className="donebutton">
        Done
      </div>

    </div>
  );
};

export default PaymentSuccess;
