import React, { useEffect, useState, useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme.js";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import TitleContext from "../UserPanel/TitleContext.js";
import "../../CSS_Files/UserPanel/Sidebar.css";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";
import axios from "axios";

const lightThemeImagePath = "../../assets/Logo-Light.png";
const darkThemeImagePath = "../../assets/logo-Dark.png";
const darklogo = "../../assets/logo.jpg";
const lightlogo = "../../assets/logowhite.png";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        outline: "none !important",
      }}
      onClick={onClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const logoImagePath =
    theme.palette.mode === "dark" ? darkThemeImagePath : lightThemeImagePath;
  const small_logo = theme.palette.mode === "dark" ? darklogo : lightlogo;
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { generatedTitle, titleHistory, setTitleHistory } =
    useContext(TitleContext);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 720);
  const [selected, setSelected] = useState(null);
  const [ChartsClicked, setChartsClicked] = useState(false);
  const [QueriesClicked, setQueriesClicked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleToDelete, setTitleToDelete] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 720);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      colorMode.setColorMode(savedTheme);
    } else {
      localStorage.setItem("theme", theme.palette.mode);
    }
  }, [theme.palette.mode, colorMode]);

  // Update selected item based on titleHistory
  useEffect(() => {
    if (titleHistory.length > 0 && titleHistory[0]?.convId) {
      setSelected(titleHistory[0].convId);
    }
    // eslint-disable-next-line
  }, [generatedTitle]);

  // Handle clearing chat history
  const handleClearHistory = async () => {
    try {
      await axios.delete(`${host}/chat/clear`, {
        headers: { "auth-token": localStorage.getItem("token") },
      });
      setTitleHistory([]);
    } catch (err) {
      console.error(err);
    }
  };

  // Confirm delete action
  const confirmDeleteHistory = async () => {
    closeDeleteDialog();
    try {
      const response = await axios.delete(`${host}/chat/deletetitle`, {
        headers: { "auth-token": localStorage.getItem("token") },
        params: { convid: titleToDelete },
      });
      console.log("Response : ", response.data);
      setTitleHistory((prevHistory) =>
        prevHistory.filter((title) => title.convId !== titleToDelete)
      );
      navigate(`/emptychat`);
    } catch (err) {
      console.error(err);
    }
  };

  // Close delete confirmation dialog
  const closeDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleMouseEnter = (convId) => setHoveredItem(convId);
  const handleMouseLeave = () => setHoveredItem(null);

  // Open delete dialog for a specific title
  const handleDeleteTitle = async (convId) => {
    setTitleToDelete(convId);
    setOpenDialog(true);
  };

  // Update selected item and handle clicks
  const setSelectedItem = (title) => {
    if (title !== "Favourite Charts") setChartsClicked(false);
    if (title !== "Favourite Queries") setQueriesClicked(false);
    setSelected(title);
  };

  // Toggle favourite charts
  const handleFavouritecharts = () => setChartsClicked(!ChartsClicked);
  // Toggle favourite queries
  const handleFavouritequeries = () => setQueriesClicked(!QueriesClicked);

  return (
    <Box
      sx={{
        width: isCollapsed ? "10px" : "230px",
        position: "relative",
        padding: "0px",
        marginRight: isCollapsed ? "50px" : "",

        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          width: "85%",
        },

        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },

        "& .pro-inner-item": {
          paddingLeft: "20px !important",
          paddingTop: "5px !important",
          paddingRight: "18px !important",
          paddingBottom: "5px !important",
        },

        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <img src={small_logo} alt="" /> : undefined}
            style={{
              color: colors.grey[100],
              outline: "none",
            }}
          >
            {!isCollapsed && (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div className="flex justify-center items-center">
                  <div>
                    {"       "}
                    <img
                      alt="profile-user"
                      src={logoImagePath}
                      style={{
                        cursor: "pointer",
                        width: "70%",
                        height: "70%",
                        paddingLeft: "5px",
                      }}
                    />
                  </div>
                  <div style={{}}>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="20px">
              <div
                style={{
                  backgroundColor: "#E6350738",
                  color: "#fff",
                  borderRadius: "25px",
                  marginLeft: "20px",
                  marginTop: "10px",
                  height: "45px",
                  width: "195px",
                  outline: "none",
                }}
              >
                <Item
                  title="New Chat"
                  to="/emptychat"
                  icon={<AddIcon />}
                  selected={selected}
                  setSelected={setSelectedItem}
                  onClick={() => setSelectedItem("New Chat")}
                />
              </div>
            </Box>
          )}
          {isCollapsed && (
            <Box mb="20px" mr={"10px"} mt="15px">
              <Item
                title=""
                to="/emptychat"
                icon={
                  <img
                    src={"../../assets/collapse/New Chat.png"}
                    alt="Add Icon"
                  />
                }
                selected={selected}
                setSelected={setSelectedItem}
                onClick={() => setSelectedItem("New Chat")}
              />
            </Box>
          )}

          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                borderRadius: "50px",
                backgroundColor: ChartsClicked ? "#202332" : "transparent",
                width: "90%",
                marginLeft: isCollapsed ? "" : "10px",
                marginBottom: "5px",
                outline: "none",
              }}
              onClick={handleFavouritecharts}
            >
              {!isCollapsed ? (
                <Item
                  title="Favourite Charts"
                  to="/favouritecharts"
                  selected={selected}
                  setSelected={setSelectedItem}
                  onClick={() => setSelectedItem("Favourite Charts")}
                />
              ) : (
                <Item
                  title=""
                  to="/favouritecharts"
                  selected={selected}
                  setSelected={setSelectedItem}
                  icon={
                    <img
                      src={"../../assets/collapse/Favorite Charts.png"}
                      alt="Add Icon"
                    />
                  }
                  onClick={() => setSelectedItem("Favourite Charts")}
                />
              )}
              <img
                src="../../assets/sidearrow.png"
                alt=""
                style={{ marginRight: "15px" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                borderRadius: "50px",
                width: "90%",
                marginLeft: isCollapsed ? "0px" : "10px",
                outline: "none",
                backgroundColor: QueriesClicked ? "#202332" : "transparent",
              }}
              onClick={handleFavouritequeries}
            >
              {!isCollapsed ? (
                <Item
                  title="Favourite Queries"
                  to="/favouritequeries"
                  selected={selected}
                  setSelected={setSelectedItem}
                  onClick={() => setSelectedItem("Favourite Queries")}
                />
              ) : (
                <Item
                  title=""
                  to="/favouritequeries"
                  selected={selected}
                  setSelected={setSelectedItem}
                  onClick={() => setSelectedItem("Favourite Queries")}
                  icon={
                    <img
                      src={"../../assets/collapse/Favorite Queries.png"}
                      alt="Add Icon"
                    />
                  }
                />
              )}
              <img
                src="../../assets/sidearrow.png"
                alt=""
                style={{ marginRight: "15px" }}
              />
            </div>

            {!isCollapsed && (
              <Box mb="10px">
                <div
                  style={{
                    color: "gray",
                    marginLeft: "40px",
                    marginTop: "10px",
                  }}
                >
                  <Typography>Recent</Typography>
                </div>
              </Box>
            )}
            {isCollapsed && (
              <Box mb="20px" mr="10px">
                <Item
                  title=""
                  icon={
                    <img
                      src={"../../assets/collapsemenu/Recent.svg"}
                      alt="Add Icon"
                    />
                  }
                  selected={selected}
                  setSelected={setSelectedItem}
                  disabled={true}
                />
              </Box>
            )}

            <div className="title-history">
              {titleHistory &&
                titleHistory.map((obj) => {
                  const title = obj.title;
                  const totalLength = title.length;
                  const shouldFade = totalLength > 20;

                  return (
                    <MenuItem
                      key={obj.convId}
                      className={`menu-item ${
                        selected === obj.convId ? "selectedtitle " : ""
                      }`}
                      onClick={() => setSelectedItem(obj.convId)}
                      onMouseEnter={() => handleMouseEnter(obj.convId)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="menu-item-content">
                        {selected === obj.convId ||
                        hoveredItem === obj.convId ? (
                          <>
                            <Item
                              title={title.slice(0, 18)}
                              to={`newchat/${obj.convId}`}
                              selected={selected}
                              className="resized-item"
                            />
                            <button
                              className="delete-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteTitle(obj.convId);
                              }}
                            >
                              <img
                                src={"../../assets/Delete.png"}
                                alt="Delete Icon"
                                style={{ width: "50%" }}
                              />
                            </button>
                          </>
                        ) : (
                          <Item
                            title={
                              <>
                                {title.slice(0, 20)}
                                {shouldFade &&
                                  title
                                    .slice(20)
                                    .split("")
                                    .map((char, index) => (
                                      <span
                                        key={index}
                                        className="faded-char"
                                        style={{
                                          "--char-opacity":
                                            0.8 -
                                            (index + 1) /
                                              (totalLength - 20 + 1),
                                        }}
                                      >
                                        {char}
                                      </span>
                                    ))}
                              </>
                            }
                            to={`newchat/${obj.convId}`}
                            selected={selected}
                          />
                        )}
                      </div>
                    </MenuItem>
                  );
                })}
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "60px",
                outline: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  outline: "none",
                }}
              >
                {!isCollapsed && (
                  <Box>
                    <div
                      style={{
                        color: "#fff",
                        marginLeft: "0px",
                      }}
                    >
                      <Item
                        title="Clear History"
                        to="/emptychat"
                        icon={<AccessTimeIcon />}
                        selected={selected}
                        setSelected={setSelectedItem}
                        onClick={handleClearHistory}
                      />
                    </div>
                  </Box>
                )}
                {isCollapsed && (
                  <Box>
                    <Item
                      title=""
                      to="/emptychat"
                      selected={selected}
                      setSelected={setSelectedItem}
                      onClick={handleClearHistory}
                      icon={
                        <img
                          src={"../../assets/collapsemenu/Clear History.svg"}
                          alt="Add Icon"
                        />
                      }
                    />
                  </Box>
                )}

                {!isCollapsed && (
                  <Box>
                    <div
                      style={{
                        color: "#fff",
                        marginLeft: "0px",
                      }}
                    >
                      <Item
                        title="Help"
                        to="/faq"
                        icon={<HelpOutlineOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelectedItem}
                      />
                    </div>
                  </Box>
                )}
                {isCollapsed && (
                  <Box>
                    <Item
                      title=""
                      to="/faq"
                      icon={
                        <img
                          style={{
                            height: "19px",
                            width: "19px",
                            color: "white",
                          }}
                          src={"../../assets/collapsemenu/Help Collapse.png"}
                          alt="Add Icon"
                        />
                      }
                      selected={selected}
                      setSelected={setSelectedItem}
                    />
                  </Box>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <ModalDelete
                    open={openDialog}
                    handleClose={closeDeleteDialog}
                    func={confirmDeleteHistory}
                    text_mod="Are you sure you want to delete this History? This action cannot be reversed."
                  />
                </div>
              </div>
            </div>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
