import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../CSS_Files/AdminPanel/AdminSidebar.css";

const Sidebar = () => {
  const [selectedSidebarIcon, setSelectedSidebarIcon] = useState(null);

  const handleClick = (icon) => {
    setSelectedSidebarIcon(icon);
  };

  return (
    <div className="AdminPanel_sidebar_container">
      <div className="AdminPanel_sidebar_logo">
        <img
          src="../../assets/Adminapp/logo.png"
          alt="logo"
          style={{ width: "75%", height: "auto" }}
        />
      </div>

      <div className="AdminPanel_sidebar_mainfields">
        <Link
          to="/dashboard"
          className={`AdminPanel_sidebar_dashboard ${
            selectedSidebarIcon === "dashboard" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("dashboard")}
        >
          <div>
            <img
              src={
                selectedSidebarIcon === "dashboard"
                  ? "../../assets/Adminapp/dashboardselected.png"
                  : "../../assets/Adminapp/Dashboard.png"
              }
              alt="dashboard"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Dashboard</div>
        </Link>

        <Link
          to="/users"
          className={`AdminPanel_sidebar_users ${
            selectedSidebarIcon === "users" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("users")}
        >
          <div>
            <img
              src={
                selectedSidebarIcon === "users"
                  ? "../../assets/Adminapp/UsersSelected.png"
                  : "../../assets/Adminapp/Users.png"
              }
              alt="users"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Users</div>
        </Link>

        <Link
          to="/uploaddocs"
          className={`AdminPanel_sidebar_documents ${
            selectedSidebarIcon === "documents" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("documents")}
        >
          <div>
            <img
              src={
                selectedSidebarIcon === "documents"
                  ? "../../assets/Adminapp/documentsselected.png"
                  : "../../assets/Adminapp/documents.png"
              }
              alt="documents"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Documents</div>
        </Link>

        <Link
          to="/connectdb"
          className={`AdminPanel_sidebar_database ${
            selectedSidebarIcon === "database" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("database")}
        >
          <div>
            <img
              src={
                selectedSidebarIcon === "database"
                  ? "../../assets/Adminapp/databaseselected.png"
                  : "../../assets/Adminapp/Database.png"
              }
              alt="database"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Database</div>
        </Link>

        <Link
          to="/upgrade"
          className={`AdminPanel_sidebar_upgrade ${
            selectedSidebarIcon === "upgrade" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("upgrade")}
        >
          <div>
            <img
              src={
                selectedSidebarIcon === "upgrade"
                  ? "../../assets/Adminapp/Upgradeselected.png"
                  : "../../assets/Adminapp/Upgrade.png"
              }
              alt="upgrade"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Upgrade</div>
        </Link>
      </div>

      <div className="AdminPanel_sidebar_bottomicons">
        <Link
          to="/help"
          className={`AdminPanel_sidebar_help ${
            selectedSidebarIcon === "help" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("help")}
        >
          <div>
            <img
              src="../../assets/Adminapp/Help.png"
              alt="help"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Help</div>
        </Link>

        {/* <Link
          to="/theme"
          className={`AdminPanel_sidebar_theme ${
            selectedSidebarIcon === "theme" ? "selectedsidebaricon" : ""
          }`}
          onClick={() => handleClick("theme")}
        >
          <div>
            <img
              src="../../assets/Adminapp/theme.png"
              alt="theme"
              style={{ width: "65%", height: "auto" }}
            />
          </div>
          <div>Dark Theme</div>
        </Link> */}
      </div>
    </div>
  );
};

export default Sidebar;
