import React from "react";
import "../../CSS_Files/Common/ddown.css";
import { useNavigate } from "react-router-dom";

const Dropdown = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleSetting = () => {
    navigate("/settings");
  };

  return (
    <div className="flex flex-col dropdownprofile">
      <ul
        className="flex flex-col gap-2"
        role="menu"
        aria-labelledby="dropdown-menu"
      >
        <li role="menuitem" onClick={handleSetting}>
          <button>Setting</button>
        </li>
        <li role="menuitem" onClick={handleLogout}>
          <button>Log Out</button>
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
