import React, { useState, useEffect } from "react";
import "../../CSS_Files/Common/ConnectDB.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "./Alertbox.js";
import { tokens } from "../../theme.js";
import { useTheme, Button } from "@mui/material";
const config = require("../../config_frontend.js");

const host = config.server.host[process.env.REACT_APP_HOST];

const ConnectDB = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [schema, setSchema] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [addingdb, setAddingdb] = useState(false);
  const [hostname, setHostname] = useState("");
  const [portname, setPortname] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [dbType, setDbType] = useState("sql");
  const [storedDetails, setStoredDetails] = useState([]);
  const [isDeletingdb, setIsDeletingdb] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [dbToDelete, setDbToDelete] = useState(null);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [hostError, setHostError] = useState(false);
  const [dbnameError, setDbnameError] = useState(false);
  const [portnameError, setPortnameError] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [schemaError, setSchemaError] = useState(false);
  const [warehouseError, setWarehouseError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [formfilled, setFormfilled] = useState(false);
  const [loading, setLoading] = useState(true);
  const[databaseLimit, setDatabaseLimit] = useState(0);
  const [showAlertForDB, setShowAlertForDB] = useState(false);

 // eslint-disable-next-line
 console.log("Form filled : ", formfilled);

  const handleClickOpenDel = (db) => {
    setDbToDelete(db);
    setOpenDeletePopup(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    getDB();
  });

  useEffect(()=>{
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    axios
      .get(`${host}/auth/getuser`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setDatabaseLimit(res.data.dbLimit);
      })
      .catch((err) => console.log(err));
  })

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-bg-dark", colors.primaryBgDark);
    root.style.setProperty("--primary-bg-light", colors.primaryBgLight);
    root.style.setProperty("--text-color", colors.textColor);
    root.style.setProperty("--primary-bg-db", colors.primaryBgfiles);
    root.style.setProperty("--bg1", colors.hovergrad1);
    root.style.setProperty("--bg2", colors.hovergrad2);
  }, [colors]);

  const getDB = async () => {
    const result = await axios.get(`${host}/connectdb/getdb`, {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    });
  
    setStoredDetails(result.data.data); 
    setLoading(false);
  };
  

  let dbtableschema;
  const validateInput = (value, setError, fieldName) => {
    const trimmedValue = value.trim();
    const isError = trimmedValue === "";
    setError(isError);
    return !isError;
  };
  useEffect(() => {
    const allFieldsFilled = [
      username,
      password,
      hostname,
      databaseName,
      dbType === "actian" ? portname : "", // Only check portname if dbType is actian
      dbType === "snowflake" ? account : "", // Only check account if dbType is snowflake
      dbType === "snowflake" ? schema : "", // Only check schema if dbType is snowflake
      dbType === "snowflake" ? warehouse : "", // Only check warehouse if dbType is snowflake
      dbType === "snowflake" ? role : "", // Only check role if dbType is snowflake
    ].every((value) => value.trim() !== "");

    // Set formfilled state based on whether all fields are filled
    setFormfilled(allFieldsFilled);
  }, [
    username,
    password,
    hostname,
    databaseName,
    dbType,
    portname,
    account,
    schema,
    warehouse,
    role,
  ]);

  const handleConnect = async (e) => {
    e.preventDefault();
    let flag = true;

    storedDetails.map((detail) => {
      if (detail.database === databaseName) {
        flag = false;
        alert("This database already exists");
      }
      return null;
    });


    if(storedDetails.length >= databaseLimit){
      flag = false;
      setShowAlertForDB(true);
      return;
    }

    console.log("cann't perform more operation");

    let validationResults = [];
    if (dbType === "sql") {
      validationResults = [
        validateInput(username, setUsernameError, "username"),
        validateInput(password, setPasswordError, "password"),
        validateInput(hostname, setHostError, "host"),
        validateInput(databaseName, setDbnameError, "database name"),
      ];
    } else if (dbType === "actian") {
      validationResults = [
        validateInput(username, setUsernameError, "username"),
        validateInput(password, setPasswordError, "password"),
        validateInput(hostname, setHostError, "host"),
        validateInput(databaseName, setDbnameError, "database name"),
        validateInput(portname, setPortnameError, "port number"),
      ];
    } else if (dbType === "snowflake") {
      validationResults = [
        validateInput(username, setUsernameError, "username"),
        validateInput(password, setPasswordError, "password"),
        validateInput(account, setAccountError, "account"),
        validateInput(schema, setSchemaError, "schema"),
        validateInput(warehouse, setWarehouseError, "warehouse"),
        validateInput(role, setRoleError, "role"),
        validateInput(hostname, setHostError, "host"),
        validateInput(databaseName, setDbnameError, "database name"),
      ];
    }
    const hasError = validationResults.some((result) => !result);

    if (hasError) {
      return;
    }
    if (!flag) {
      return;
    }
    if (flag) {
      const url = `${host}/connectdb/storedb`;
      setAddingdb(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          username,
          password,
          host: hostname,
          database: databaseName,
          dbtype: dbType,
          role,
          warehouse,
          port: portname,
          schema,
          account,
        }),
      });
      const output = await response.json();

      console.log(output);

      dbtableschema = output.schema;

      console.log("Schema -> ", dbtableschema);
      const id = output.id;
      console.log(id);
      setAddingdb(false);
      setUsername("");
      setPassword("");
      setHostname("");
      setDatabaseName("");
      setDbType("sql");
      getDB();
      navigate(`/database/${id}`);
    } else {
      alert(`${databaseName} already exists`);
    }
  };


 
  const deleteDB = async () => {
    setIsDeletingdb(true);
    await fetch(`${host}/connectdb/deletedb/${dbToDelete._id}`, {
      method: "DELETE",
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    });
    const newDBs = storedDetails.filter((db) => db._id !== dbToDelete._id);
    setStoredDetails(newDBs);
    setIsDeletingdb(false);
    setDbToDelete(null);
    setOpenDeletePopup(false);
  };

  const renderDatabaseSpecificFields = () => {
    switch (dbType) {
      case "sql":
        return (
          <>
            <div className="connectdb-datafield">
              <label>User Name</label>
              <input
                className={`connectdb-textfield ${usernameError ? "error" : ""}`} // Apply 'error' class if usernameError is true
                type="text"
                placeholder="Enter user name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="connectdb-datafield">
              <label>Password</label>
              <input
                className={`connectdb-textfield ${passwordError ? "error" : ""}`}
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </>
        );
      case "actian":
        return (
          <>
            <div className="connectdb-datafield">
              <label>Username</label>
              <input
                className={`connectdb-textfield ${usernameError ? "error" : ""}`}
                type="text"
                placeholder="Enter user name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                //required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Password</label>
              <input
                className={`connectdb-textfield ${passwordError ? "error" : ""}`}
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                //required
              />
            </div>
            {/* <div className="connectdb-datafield">
                <label>Field3</label>
                <input
                  className="connectdb-textfield"
                  type="text"
                  placeholder="Enter host"
                  value={hostname}
                  onChange={(e) => setHostname(e.target.value)}
                  ////required
                />
              </div> */}
            <div className="connectdb-datafield">
              <label>Port Number</label>
              <input
                className={`connectdb-textfield ${portnameError ? "error" : ""}`}
                type="text"
                placeholder="Enter Port"
                value={portname}
                onChange={(e) => setPortname(e.target.value)}
                ////required
              />
            </div>
          </>
        );
      case "DB2":
        return (
          <>
            <div className="connectdb-datafield">
              <label>Username</label>
              <input
                className={`connectdb-textfield ${usernameError ? "error" : ""}`}
                type="text"
                placeholder="Enter user name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                //required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Password</label>
              <input
                className={`connectdb-textfield ${passwordError ? "error" : ""}`}
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                //required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Port Number</label>
              <input
                className={`connectdb-textfield ${portnameError ? "error" : ""}`}
                type="text"
                placeholder="Enter Port"
                value={portname}
                onChange={(e) => setPortname(e.target.value)}
                //required
              />
            </div>
          </>
        );

      case "snowflake":
        return (
          <>
            <div className="connectdb-datafield">
              <label>User Name</label>
              <input
                className={`connectdb-textfield ${usernameError ? "error" : ""}`}
                type="text"
                placeholder="Enter user name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                ////required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Password</label>
              <input
                className={`connectdb-textfield ${passwordError ? "error" : ""}`}
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                ////required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Account</label>
              <input
                className={`connectdb-textfield ${accountError ? "error" : ""}`}
                type="text"
                placeholder="Enter Account"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                ////required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Schema</label>
              <input
                className={`connectdb-textfield ${schemaError ? "error" : ""}`}
                type="text"
                placeholder="Enter schema"
                value={schema}
                onChange={(e) => setSchema(e.target.value)}
                ////required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Warehouse</label>
              <input
                className={`connectdb-textfield ${warehouseError ? "error" : ""}`}
                type="text"
                placeholder="Enter warehouse"
                value={warehouse}
                onChange={(e) => setWarehouse(e.target.value)}
                ////required
              />
            </div>
            <div className="connectdb-datafield">
              <label>Role</label>
              <input
                className={`connectdb-textfield ${roleError ? "error" : ""}`}
                type="text"
                placeholder="Enter role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                ////required
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="connect-db-container">
      <div className="left-container-connect-db"></div>
      <div className="mid-container-connect-db">
        <div className="connectdb-heading">
          <div>Connect database</div>
        </div>

        <form onSubmit={handleConnect}>
          <div className="connectdb-fields">
            {renderDatabaseSpecificFields()}
            <div className="connectdb-datafield">
              <label>Host</label>
              <input
                className={`connectdb-textfield ${hostError ? "error" : ""}`}
                type="text"
                placeholder="Enter host"
                value={hostname}
                onChange={(e) => setHostname(e.target.value)}
              />
            </div>

            <div className="connectdb-datafield">
              <label>Database</label>
              <input
                className={`connectdb-textfield ${dbnameError ? "error" : ""}`}
                type="text"
                placeholder="Enter database"
                value={databaseName}
                onChange={(e) => setDatabaseName(e.target.value)}
              />
            </div>

            <div className="connectdb-datafield">
              <label>DBType</label>
              <select
                className="connectdb-textfield"
                value={dbType}
                onChange={(e) => setDbType(e.target.value)}
              >
                <option value="sql">sql</option>
                <option value="actian">Actian</option>
                <option value="snowflake">Snowflake</option>
                <option value="DB2">DB2</option>
              </select>
            </div>
            <button className="connecttdbbutton" type="submit" style={{ opacity: 1 }}>
              <img
                src="../../assets/Connect.png"
                alt=""
                width={15}
                height={15}
              ></img>
              <p>Connect</p>
            </button>
          </div>
        </form>

        {addingdb && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {isDeletingdb && (
          <React.Fragment>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {/* {setTimeout(() => setIsDeletingdb(false), 1)} */}
          </React.Fragment>
        )}
        <div className="headingtableconnectdb">Established Connections</div>
        {/* Established Connection table */}
        {storedDetails.length > 0 && (
          <div className="">
            <div className="currentconn justify-center ">
              {/* <div className="w-[5%] h-full border-1 border-blue-50"></div> */}
              <div className="connectdb-tab-parent">
                {" "}
                <table className="connectdb-tab ">
                  <thead>
                    <tr>
                      <th>DB Name</th>
                      <th>DB Host Location</th>
                      <th>User Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storedDetails.map((detail, index) => (
                      <tr
                        key={index}
                        className={`fileeach ${
                          !detail.schema.dbDef ? "missing-dbdef" : ""
                        }`}
                        title={detail.schema.dbDef ? null : ""}
                      >
                        <td>
                          <div className="border-container">
                            {/* <div>test</div> */}
                            <div className="firstcol">
                              <Link to={`/database/${detail._id}`}>
                                {detail.database}
                              </Link>
                            </div>
                            {/* {!detail.schema.dbDef && (
                      <Alert icon={<CloseIcon fontSize="inherit" />} severity="error">
                        Database definition is missing for {detail.database}!
                      </Alert>
                    )} */}
                          </div>
                        </td>
                        <td id="host-table-col">
                          <p>{detail.host}</p>
                        </td>
                        <td>{detail.username}</td>
                        <td>
                          <div className="editicons">
                            <button className="action-btn">
                              <Link to={`/database/${detail._id}`}>
                                <img
                                  src="../../assets/Edit.png"
                                  alt="edit"
                                  className="mr-5 cursor-pointer "
                                />
                              </Link>
                            </button>
                            <button className=" action-btn">
                              {" "}
                              <img
                                src="../../assets/Delete.png"
                                alt="del "
                                className="mr-3 cursor-pointer"
                                onClick={() => handleClickOpenDel(detail)}
                              ></img>
                            </button>
                          </div>
                        </td>
                        <React.Fragment>
                          <Dialog
                            open={openDeletePopup}
                            onClose={() => setOpenDeletePopup(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                              style: { backgroundColor: "#2F2F2F" },
                            }}
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Are you sure?"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Do you want to permanently delete{" "}
                                {dbToDelete?.database}?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button id="modal_btn" onClick={deleteDB}>
                                Delete
                              </Button>
                              <Button
                                id="modal_btn"
                                onClick={() => setOpenDeletePopup(false)}
                                autoFocus
                              >
                                Cancel
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="w-[5%] h-full border-1 border-blue-50"></div> */}
            </div>
          </div>
        )}
      </div>
      <div className="right-container-connect-db"></div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {showAlertForDB && (
            <Alert
              message="Your DB limit is exceeded! Please contact the organization"
              type="error"
              onClose={() => setShowAlertForDB(false)}
            />
          )}
    </div>
  );
};
export default ConnectDB;
//formfilled ? 1 : 0.5
