import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "hidden", 
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
};

const iframeStyle = {
  width: "100%",
  height: "calc(100% - 50px)",
  border: "none",
  flexGrow: 1,
};

const headerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "10px",
};

const imageStyle = {
  maxWidth: "100%",
  maxHeight: "calc(100% - 50px)",
  objectFit: "contain",
  margin: "auto",
};

const FileModal = ({ showModal, closeModal, fileUrl, fileExtension }) => {
  const [loading, setLoading] = useState(true);
  
  const downloadFile = () => {
    const link = document.createElement("a");

    if (fileExtension === ".png") {
      link.href = `data:image/png;base64,${fileUrl}`;
      link.download = "image.png"; 
    } else {
      link.href = fileUrl;
      link.download = fileUrl.split("/").pop();
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setLoading(true);
  }, [fileUrl]);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={style}>
        <div style={headerStyle}>
          <IconButton onClick={downloadFile} aria-label="download">
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={closeModal} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {fileExtension === ".pdf" && (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            style={iframeStyle}
            title="PDF Viewer"
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
        )}
        {fileExtension === ".xlsx" && (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileUrl
            )}`}
            style={iframeStyle}
            title="Excel Viewer"
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
        {fileExtension === ".ppt" && (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileUrl
            )}`}
            style={iframeStyle}
            title="PowerPoint Viewer"
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
        {fileExtension === ".txt" && (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            style={iframeStyle}
            title="Text Viewer"
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
        )}
        {fileExtension === ".docx" && (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileUrl
            )}`}
            style={iframeStyle}
            title="Word Viewer"
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
        {fileExtension === ".csv" && (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            style={iframeStyle}
            title="CSV Viewer"
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
        )}
        {fileExtension === ".png" && (
          <img
            src={`data:image/png;base64,${fileUrl}`}
            alt="Response"
            style={imageStyle}
            title="Image Viewer"
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
        )}
      </Box>
    </Modal>
  );
};

export default FileModal;
