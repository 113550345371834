import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../CSS_Files/UserPanel/Login.css";
import "../../App.css";
import { useGoogleLogin } from "@react-oauth/google";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Login = (props) => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [emailissue, setemailissue] = useState(false);
  const [passissue, setpassissue] = useState(false);

  let navigate = useNavigate();

  //To Handle form submission for login
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = credentials;

    if (!email || !password) {
      if (!email) {
        setemailissue(true);
      }
      if (!password) {
        setpassissue(true);
      }
      alert("Please enter both email and password");
      return;
    }

    const response = await fetch(`${host}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const json = await response.json();
    console.log(json);

    if (json.success) {
      localStorage.setItem("token", json.authToken);
      navigate("/emptychat");
    } else {
      if (json.msg && json.msg === "no access") {
        alert(
          "Sorry! You're not an authorised user for this platform. Please ask your admin to give you access."
        );
      } else {
        alert("Invalid credentials");
      }
    }
  };

  //To toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //To Handle input change
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setemailissue(false);
    } else if (e.target.name === "password") {
      setpassissue(false);
    }
  };

  //To Handle Google login
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const resp = await fetch(`${host}/auth/glogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ access_token: response.access_token }),
        });

        const json = await resp.json();
        console.log(json);

        if (json.success) {
          localStorage.setItem("token", json.authToken);
          navigate("/emptychat");
        } else {
          if (json.msg && json.msg === "no access") {
            alert(
              "Sorry! You're not an authorised user for this platform. Please ask your admin to give you access."
            );
          } else {
            alert("Invalid credentials");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <div className="login-page-first-page">
        <div className="login-box-first-page">
        <div className="logo-first-page">
            <img alt="logo" src="../../assets/Logo-Light.png"></img>
          </div>

          <div className="no-account-first-page">
            <div className="dont-account-first-page">Don't have an account?</div>
            <div className="sign-up-first-page">
              <Link id="signup" to="/signup">
                Sign up
              </Link>
            </div>
          </div>


          <div className="input-fields-first-page">
            <div>
              <label className="email-first-page">Email</label>
            </div>
            <div>
              <input
                type="email"
                className="email-input-first-page"
                id="email"
                name="email"
                value={credentials.email}
                onChange={onChange}
                placeholder="Enter your email"
              ></input>
            </div>
            <div className={emailissue ? "error-msg1-first-page" : " hidden"}>
              Fill in email
            </div>

            <div className="password-first-page">
              <label className="pass-first-page">Password</label>
              <label className="forgot-pass-first-page">Forgot password?</label>
            </div>

            <div className="forgotpassword-first-page">
              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  className="pass-input-first-page"
                  id="password"
                  name="password"
                  value={credentials.password}
                  onChange={onChange}
                  placeholder="Enter your password"
                ></input>
              </div>
              <div>
                <img
                  className="pass-input-img-first-page"
                  src={"../../assets/view.png"}
                  alt="View"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>

            <div className={passissue ? "error-msg2-first-page" : "error-msg2-first-page hidden"}>
              Fill in password
            </div>
          </div>

          <button className="sign-in-first-page" onClick={handleSubmit}>
            SIGN IN &rarr;
          </button>

          <div className="border-with-text-first-page">
            <div className="border-line-first-page"></div>
            <div className="text-between-first-page">Or sign in with</div>
            <div className="border-line-first-page"></div>
          </div>

          <div className="buttons-row-first-page">
            <div>
              <button className="btn_alt-first-page" onClick={() => login()}>
                <div className="btns-first-page">
                  <div>
                    <img
                      className="btnimg google-first-page"
                      src={"../../assets/Google.png"}
                      alt="Google"
                    />
                  </div>
                  <div className="btntext-first-page">Google</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
