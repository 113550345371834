import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import DatabaseDef from "./dynamic-components/Common/DatabaseDef";
import ConnectDatabase from "./dynamic-components/Common/ConnectDB";
import UploadDocs from "./dynamic-components/Common/UploadDocs";
import XLSXViewer from "./dynamic-components/Common/Xlsxviewer";

import Favouritecharts from "./dynamic-components/UserPanel/favouritecharts";
import Favouritequeries from "./dynamic-components/UserPanel/favouritequeries";
import Topbar from "./dynamic-components/UserPanel/Topbar";
import Sidebar from "./dynamic-components/UserPanel/Sidebar";
import Login from "./dynamic-components/UserPanel/Login";
import ChatComponent from "./dynamic-components/UserPanel/NewChat";
import Signup from "./dynamic-components/UserPanel/Signup";
import SettingsPage from "./dynamic-components/UserPanel/Settings";
import { TitleProvider } from "./dynamic-components/UserPanel/TitleContext";
import EmailVerify from "./dynamic-components/UserPanel/emailverify";

import AdminLogin from "./dynamic-components/AdminPanel/AdminLogin";
import AdminSignup from "./dynamic-components/AdminPanel/AdminSignup";
import AdminSidebar from "./dynamic-components/AdminPanel/AdminSideBar";
import AdminTopBar from "./dynamic-components/AdminPanel/AdminTopBar";
import Dashboard from "./dynamic-components/AdminPanel/Dashboard";
import Upgrade from "./dynamic-components/AdminPanel/Upgrade";
import Users from "./dynamic-components/AdminPanel/User";
import OrderInfo from "./dynamic-components/AdminPanel/OrderInfo";
import PaymentSucess from "./dynamic-components/AdminPanel/PaymentSuccessful";
import AdminEmailVerify from "./dynamic-components/AdminPanel/adminemailverify";
import AdminUserVerify from "./dynamic-components/AdminPanel/adminuserverify";

import PrivateRoute from "./dynamic-components/Protectedroute";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import "./index.css";

function App() {
  console.log("env -> ", process.env.REACT_APP_HOST);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [alert, setAlert] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
    console.log("Alert : ", alert);
  };

  useEffect(() => {
    if (
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/signup") ||
      location.pathname.startsWith("/adminlogin") ||
      location.pathname.startsWith("/adminsignup")
    ) {
      localStorage.clear();
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (
      !token &&
      !location.pathname.startsWith("/login") &&
      !location.pathname.startsWith("/signup") &&
      !location.pathname.startsWith("/adminlogin") &&
      !location.pathname.startsWith("/adminsignup") &&
      !location.pathname.startsWith("/verify")
    ) {
      navigate("/login");
    }
  }, [location.pathname, navigate]);

  const isLoginPage = location.pathname === "/login";
  const isSignupPage = location.pathname === "/signup";
  const isAdminLoginPage = location.pathname === "/adminlogin";
  const isAdminSignupPage = location.pathname === "/adminsignup";
  const isVerificationPage = location.pathname.startsWith("/verify");

  useEffect(() => {
    if (isAdminLoginPage || isAdminSignupPage) {
      localStorage.setItem("isAdmin", true);
      setIsAdmin(true);
    } else if (isLoginPage || isSignupPage) {
      localStorage.setItem("isAdmin", false);
      setIsAdmin(false);
    }
  }, [isAdminLoginPage, isAdminSignupPage, isLoginPage, isSignupPage]);

  useEffect(() => {
    const storedIsAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(storedIsAdmin);
    console.log("isAdmin state: ", storedIsAdmin);
  }, [location.pathname]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TitleProvider>
          <div className="app">
            {!isLoginPage &&
              !isSignupPage &&
              !isAdminLoginPage &&
              !isAdminSignupPage &&
              !isVerificationPage &&
              (isAdmin ? (
                <AdminSidebar isSidebar={isSidebar} />
              ) : (
                <Sidebar isSidebar={isSidebar} />
              ))}
            <main className="content">
              {!isLoginPage &&
                !isSignupPage &&
                !isAdminLoginPage &&
                !isAdminSignupPage &&
                !isVerificationPage &&
                (isAdmin ? (
                  <AdminTopBar setIsSidebar={setIsSidebar} />
                ) : (
                  <Topbar setIsSidebar={setIsSidebar} />
                ))}
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route
                  exact
                  path="/login"
                  element={<Login showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/adminlogin"
                  element={<AdminLogin showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/signup"
                  element={<Signup showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/adminsignup"
                  element={<AdminSignup showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/uploaddocs"
                  element={<UploadDocs showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/connectdb"
                  element={<ConnectDatabase showAlert={showAlert} />}
                />
                <Route
                  exact
                  path="/databasedef"
                  element={<DatabaseDef showAlert={showAlert} />}
                />

                <Route
                  path="/favouritecharts"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <Favouritecharts />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/favouritequeries"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <Favouritequeries />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/emptychat"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <ChatComponent />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/newchat/:id"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <ChatComponent />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/database/:id"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <DatabaseDef />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/XLSXViewer/:id"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <XLSXViewer />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <SettingsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/upgrade"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <Upgrade />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/orderinfo"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <OrderInfo />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/paymentsuccess"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <PaymentSucess />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["admin"]}>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute isAdmin={isAdmin} allowedRoles={["user"]}>
                      <ChatComponent />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/verify/:token"
                  element={<EmailVerify />}
                />
                <Route
                  path="/verifyadmin/:token"
                  element={<AdminEmailVerify/>}
                />
                <Route
                  path="/verifyuser/:token"
                  element={<AdminUserVerify/>}
                />
              </Routes>
            </main>
          </div>
        </TitleProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
