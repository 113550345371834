import React, { useState, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { tokens } from "../../theme.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dropdown from "../Common/DropDown.jsx";
import "../../CSS_Files/UserPanel/Topbar.css";
import { useTheme, ThemeProvider } from "@mui/material";

const Topbar = () => {
  const [name, setName] = useState("Profile");
  const [email, setEmail] = useState("email@gmail.com");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const defaultUserImagePath = "../../assets/Userlogo.png";
  const [userImage, setUserImage] = useState(defaultUserImagePath);
  const dropdownRef = useRef(null);

  const [isScreenWidthGreaterThan720px, setIsScreenWidthGreaterThan720px] =
    useState(false);
  const [isUploadHovered, setIsUploadHovered] = useState(false);
  const [isConnectHovered, setIsConnectHovered] = useState(false);
  const [dbAllowed, setDbAllowed] = useState(false);

  //handle navigation to upload documents page
  const upload = () => {
    navigate("/uploaddocs");
  };

  //shorten the email address for display
  function shortenEmail(email) {
    var atIndex = email.indexOf("@");
    if (atIndex === -1) {
      return "Invalid email format";
    }

    var username = email.substring(0, 2);
    var domain = email.substring(email.lastIndexOf("@"));
    return username + "··········" + domain;
  }

  // Fetch user data on component mount
  useEffect(() => {
    const config = require("../../config_frontend.js");
    const host = config.server.host[process.env.REACT_APP_HOST];

    axios
      .get(`${host}/auth/getuser`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setName(res.data.name);
        setEmail(res.data.email);
        setDbAllowed(res.data.dbAllowed);
        setUserImage(res.data.picture || defaultUserImagePath);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  //handle database connection
  const connectDatabase = () => {
    if (dbAllowed) {
      navigate("/connectdb");
    } else {
      alert("You don't have access to the database.");
    }
  };

  // Update screen width state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsScreenWidthGreaterThan720px(window.innerWidth > 720);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleUploadMouseEnter = () => setIsUploadHovered(true);
  const handleUploadMouseLeave = () => setIsUploadHovered(false);
  const handleConnectMouseEnter = () => setIsConnectHovered(true);
  const handleConnectMouseLeave = () => setIsConnectHovered(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="flex justify-between pt-2 pl-4 pb-2 border-b border-gray-100/10 max-h-60 w-full relative z-10 md:shrink-0">
        <div className="flex whitespace-nowrap w-full">
          <div className="flex justify-start items-center whitespace-nowrap w-[50%]">
            <Button
              className="ab"
              variant="contained"
              style={{
                backgroundColor: colors.grey[500],
                color: colors.text[100],
                borderRadius: "25px",
                textTransform: "none",
                width: "150px",
                fontSize: "14px",
              }}
              onClick={upload}
              onMouseEnter={handleUploadMouseEnter}
              onMouseLeave={handleUploadMouseLeave}
            >
              <div className="flex justify-center items-center">
                <div>
                  <img
                    alt="upload-documents"
                    src={
                      isUploadHovered
                        ? "../../assets/UploadDocuments_White.png"
                        : "../../assets/UploadDocuments.png"
                    }
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {isScreenWidthGreaterThan720px && <div>Documents</div>}
              </div>
            </Button>

            {/* Connect Database Button */}
            {dbAllowed && (
              <Button
                className="ab"
                variant="contained"
                style={{
                  backgroundColor: colors.grey[500],
                  color: colors.text[100],
                  borderRadius: "25px",
                  textTransform: "none",
                  marginLeft: "15px",
                  width: "150px",
                  fontSize: "14px",
                }}
                onClick={connectDatabase}
                onMouseEnter={handleConnectMouseEnter}
                onMouseLeave={handleConnectMouseLeave}
              >
                <div className="flex justify-center items-center">
                  <div>
                    <img
                      alt="connect-database"
                      src={
                        isConnectHovered
                          ? "../../assets/ConnectDatabase_White.png"
                          : "../../assets/ConnectDatabase.png"
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {isScreenWidthGreaterThan720px && <div>Database</div>}
                </div>
              </Button>
            )}
          </div>

          {/* User Profile Section */}
          <div className="header-right">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="300px"
              ref={dropdownRef}
              onClick={() => setDropdownOpen((prev) => !prev)}
            >
              <img
                alt="profile-user"
                src={userImage}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  height: "37px",
                  width: "37px",
                }}
              />
              {isScreenWidthGreaterThan720px && (
                <div className="header-right-name">
                  <div className="flex flex-col">
                    <div style={{ fontSize: "14px" }}>{name}</div>
                    <div className="opacity-50" style={{ fontSize: "14px" }}>
                      {shortenEmail(email)}
                    </div>
                  </div>
                  <div className="header-ddown">
                    <img src="../../assets/ddown.png" alt="dropdown" />
                  </div>
                </div>
              )}
              <div
                className="ddown"
                style={{
                  position: "absolute",
                  bottom: "calc(-100% + 105px)",
                  right: "20px",
                  zIndex: "999",
                  display: dropdownOpen ? "block" : "none",
                }}
              >
                <Dropdown className="absolute" />
              </div>
            </Box>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Topbar;
