import React from "react";
import "../../CSS_Files/AdminPanel/Invitationdialogbox.css";

const InvitationSuccess = ({ email, onClose }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `http://20.198.223.174.nip.io:3000/verifyuser/${email}`
    );
  };

  return (
    <div className="invitation-dialog-container">
      <div className="invitation-dialog-close-button" onClick={onClose}>
        <img
          src="../../assets/Adminapp/closebutton.png"
          alt="Close Button"
          style={{ width: "15px" }}
        />
      </div>
      <div className="invitation-dialog-successful-image">
        <img
          src="../../assets/Adminapp/invitationsuccesful.png"
          alt="Successful"
          style={{ width: "20%" }}
        />
      </div>

      <div className="invitation-dialog-first-heading">
        Invitations Sent Successfully!
      </div>
      <div className="invitation-dialog-second-heading">
        <p>
          We sent an invitation to join qRIZZ at{" "}<br></br>
          <span style={{ paddingLeft: "30px" }}>{email}</span>
        </p>
      </div>

      <div className="invitation-dialog-bottom-section">
        <p>If you prefer, you can share the invitation link:</p>
        <div className="invitation-dialog-link-box">
          <img
            src="../../assets/Adminapp/link.png"
            alt="Link"
            style={{ width: "20px", marginLeft: "5px" }}
          />
          <input
            type="text"
            value={`http://20.198.223.174.nip.io:3000/verifyuser/${email}`}
            readOnly
          />
          <button onClick={handleCopy}>Copy</button>
        </div>
      </div>
    </div>
  );
};

export default InvitationSuccess;
