import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "../../CSS_Files/UserPanel/emailverify.css";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const { token } = useParams();
  console.log("token : ", token);

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const response = await axios.get(`${host}/auth/verify/${token}`);
        console.log(response);
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [token]);

  return (
    <>
      {validUrl ? (
        <div className="email-container">
          <img
            src="../../assets/success.png"
            alt="success_img"
            className="email-success_img"
          />
          <h1 className="email-h1">Email verified successfully</h1>
          <Link to="/login">
            <button className="email-button">
              To Login <span className="email-ancor">click here</span>
            </button>
          </Link>
        </div>
      ) : (
        <h1 className="email-h1"> OOPS!! Page Not Found</h1>
      )}
    </>
  );
};

export default EmailVerify;
