import React from "react";
import "../../CSS_Files/Common/Alertbox.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Alert = ({ message, type, onClose }) => {
  return (
    <div className="alert-box-common">
      <div className="alert-content-del">
        <div className="flex justify-end pr-4 pt-2">
           <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <div className=" p-1 mt-1 text-base prompt ml-3 mr-3 " style={{fontSize:"20px"}}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default Alert;

