// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
}

.AdminPanel_Dashboard_container {
  background-color: #030c20;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.AdminPanel_Dashboard_Topcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.AdminPanel_Dashboard_leftcontainer {
  width: 5%;
}

.AdminPanel_Dashboard_middlecontainer {
  width: 90%;
}

.AdminPanel_Dashboard_rightcontainer {
  width: 5%;
}

.AdminPanel_topcontainer-dashboard {
  display: flex;
  gap: 20px;
  margin-top: 3%;
}

.AdminPanel_Queries,
.AdminPanel_License {
  background-color: #1b2335;
  flex: 1 1;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  padding-bottom: 25px;
}

.AdminPanel_QueriesText,
.AdminPanel_LicenseText {
  font-family: Inter;
  font-size: 14px;
  line-height: 26px;
}

.AdminPanel_QueriesValue,
.AdminPanel_LicenseValue {
  font-family: Inter;
  font-size: 46px;
  line-height: 26px;
}

.AdminPanel_Dashboard_Bottomcontainer {
  flex: 1 1;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/AdminPanel/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;EACT,cAAc;AAChB;;AAEA;;EAEE,yBAAyB;EACzB,SAAO;EACP,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,oBAAoB;AACtB;;AAEA;;EAEE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;;EAEE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,aAAa;AACf","sourcesContent":["* {\r\n  padding: 0;\r\n  margin: 0;\r\n}\r\n\r\n.AdminPanel_Dashboard_container {\r\n  background-color: #030c20;\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.AdminPanel_Dashboard_Topcontainer {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.AdminPanel_Dashboard_leftcontainer {\r\n  width: 5%;\r\n}\r\n\r\n.AdminPanel_Dashboard_middlecontainer {\r\n  width: 90%;\r\n}\r\n\r\n.AdminPanel_Dashboard_rightcontainer {\r\n  width: 5%;\r\n}\r\n\r\n.AdminPanel_topcontainer-dashboard {\r\n  display: flex;\r\n  gap: 20px;\r\n  margin-top: 3%;\r\n}\r\n\r\n.AdminPanel_Queries,\r\n.AdminPanel_License {\r\n  background-color: #1b2335;\r\n  flex: 1;\r\n  border-radius: 10px;\r\n  color: white;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n  padding: 15px;\r\n  padding-bottom: 25px;\r\n}\r\n\r\n.AdminPanel_QueriesText,\r\n.AdminPanel_LicenseText {\r\n  font-family: Inter;\r\n  font-size: 14px;\r\n  line-height: 26px;\r\n}\r\n\r\n.AdminPanel_QueriesValue,\r\n.AdminPanel_LicenseValue {\r\n  font-family: Inter;\r\n  font-size: 46px;\r\n  line-height: 26px;\r\n}\r\n\r\n.AdminPanel_Dashboard_Bottomcontainer {\r\n  flex: 1;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
