// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-navi {
  margin-right: 0;
  border: 1px solid #2a354a8a;
  border-radius: 22px;
  width: auto;
}

#navi {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.current-page {
  font-weight:bolder;
  filter: invert(58%) sepia(94%) saturate(1241%) hue-rotate(340deg)
    brightness(90%) contrast(91%);
  
}

.page-link{
  color: none;
  background-color: none;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/Common/Pagination.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB;iCAC+B;;AAEjC;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".page-navi {\n  margin-right: 0;\n  border: 1px solid #2a354a8a;\n  border-radius: 22px;\n  width: auto;\n}\n\n#navi {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  box-shadow: none;\n}\n\n.current-page {\n  font-weight:bolder;\n  filter: invert(58%) sepia(94%) saturate(1241%) hue-rotate(340deg)\n    brightness(90%) contrast(91%);\n  \n}\n\n.page-link{\n  color: none;\n  background-color: none;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
