// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalback {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content-del{
    width: 27%;
    height: 30%;
    border-radius:18px ;
    background: #2F2F2F;
    box-shadow: "1px 1px 10px 5px #888888";
    padding: "20px";
   
}
.heading-modal-del{
    /* display: inline-block; */
    justify-content: center;
    padding: 5px;
    font-size: 22px;
    font:'Inter';
  margin-top: 2%;
 
}
.btn-modal-del{
    background: linear-gradient(90deg, #E63507 0%, #FF633A 100%);
    width: 25%;
    height: 60%;
   margin-left: 5%;
    padding: 2px;
    border-radius: 25px;

}
.btn-del{
    background: linear-gradient(90deg, #676767 0%, #C8B0AA 100%);

    border-radius: 25px;
}
.prompt{
    opacity: 80%;
    overflow-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/scenes/Modals/modal_delete.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;;AAEnB;AACA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,YAAY;EACd,cAAc;;AAEhB;AACA;IACI,4DAA4D;IAC5D,UAAU;IACV,WAAW;GACZ,eAAe;IACd,YAAY;IACZ,mBAAmB;;AAEvB;AACA;IACI,4DAA4D;;IAE5D,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".modalback {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.modal-content-del{\n    width: 27%;\n    height: 30%;\n    border-radius:18px ;\n    background: #2F2F2F;\n    box-shadow: \"1px 1px 10px 5px #888888\";\n    padding: \"20px\";\n   \n}\n.heading-modal-del{\n    /* display: inline-block; */\n    justify-content: center;\n    padding: 5px;\n    font-size: 22px;\n    font:'Inter';\n  margin-top: 2%;\n \n}\n.btn-modal-del{\n    background: linear-gradient(90deg, #E63507 0%, #FF633A 100%);\n    width: 25%;\n    height: 60%;\n   margin-left: 5%;\n    padding: 2px;\n    border-radius: 25px;\n\n}\n.btn-del{\n    background: linear-gradient(90deg, #676767 0%, #C8B0AA 100%);\n\n    border-radius: 25px;\n}\n.prompt{\n    opacity: 80%;\n    overflow-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
