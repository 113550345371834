// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-history {
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  padding: 0px;
  margin: 0px;
}

.menu-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.menu-item.selectedtitle .menu-item-content {
  background-color: #202332;
  border-radius: 10px;
  color: white !important;
}

.menu-item .menu-item-content:hover {
  background-color: #202332;
  border-radius: 10px;
  color: white !important;
}


.titlesssss {
  font-size: 15px !important;
  font-weight: 500px !important;
}

.clearhistoryy {
  color: grey;
  outline: none !important;
}


.faded-char {
  opacity: var(--char-opacity);
  transition: opacity 0.3s ease;
}


.resized-item {
  width: 40%;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/UserPanel/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;;AAGA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;;AAGA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,wBAAwB;AAC1B;;;AAGA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;;AAGA;EACE,UAAU;AACZ","sourcesContent":[".title-history {\r\n  max-height: 250px;\r\n  overflow-y: auto;\r\n  scrollbar-width: none;\r\n  color: white;\r\n  padding: 0px;\r\n  margin: 0px;\r\n}\r\n\r\n.menu-item-content {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n\r\n.menu-item.selectedtitle .menu-item-content {\r\n  background-color: #202332;\r\n  border-radius: 10px;\r\n  color: white !important;\r\n}\r\n\r\n.menu-item .menu-item-content:hover {\r\n  background-color: #202332;\r\n  border-radius: 10px;\r\n  color: white !important;\r\n}\r\n\r\n\r\n.titlesssss {\r\n  font-size: 15px !important;\r\n  font-weight: 500px !important;\r\n}\r\n\r\n.clearhistoryy {\r\n  color: grey;\r\n  outline: none !important;\r\n}\r\n\r\n\r\n.faded-char {\r\n  opacity: var(--char-opacity);\r\n  transition: opacity 0.3s ease;\r\n}\r\n\r\n\r\n.resized-item {\r\n  width: 40%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
