// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownprofile {
    position: absolute;
    top: 3rem;
    right: 2.5rem;
    width: 120px; 
    padding: 15px;
    border-radius: 10px; 
    z-index: 999;
    background-color: #2A354A;
    color: white;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
    font-size: 14px;
  }
  

  li{
    font-size: medium;
  }
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/Common/ddown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,wCAAwC;IACxC,eAAe;EACjB;;;EAGA;IACE,iBAAiB;EACnB","sourcesContent":[".dropdownprofile {\n    position: absolute;\n    top: 3rem;\n    right: 2.5rem;\n    width: 120px; \n    padding: 15px;\n    border-radius: 10px; \n    z-index: 999;\n    background-color: #2A354A;\n    color: white;\n    border: none;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); \n    font-size: 14px;\n  }\n  \n\n  li{\n    font-size: medium;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
