import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import "../../CSS_Files/AdminPanel/Upgrade.css";
import axios from "axios";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Upgrade = () => {
  const [adminData, setAdminData] = useState(null);
  const [queryUsed, setQueryUsed] = useState(0);
  const [queryTotal, setQueryTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const transactionHistory = [
    {
      transactionNo: 137147,
      datePaid: "03-06-2024: 10:20",
      cardType: "Credit",
      cardNumber: "2424 2424 242424",
      amount: "$48.00",
      status: "Paid",
      receipts: "View/Download",
    },
    {
      transactionNo: 137148,
      datePaid: "04-06-2024: 11:20",
      cardType: "Credit",
      cardNumber: "2525 2525 252525",
      amount: "$50.00",
      status: "Paid",
      receipts: "View/Download",
    },
    {
      transactionNo: 137149,
      datePaid: "05-06-2024: 12:20",
      cardType: "Debit",
      cardNumber: "2626 2626 262626",
      amount: "$52.00",
      status: "Paid",
      receipts: "View/Download",
    },
  ];

  useEffect(() => {
    axios
      .get(`${host}/auth/getAdmin`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAdminData(res.data);
        setQueryUsed(res.data.queryCount);
        setQueryTotal(res.data.queryLimit);
        console.log("Admin data -> ", res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(adminData);

  return (
    <div className="Upgrade-container">
      <div className="Upgrade-left-container"></div>
      <div className="Upgrade-middle-container">
        <div className="Upgrade-upperpart">
          <p className="Upgrade-heading">Plan Information</p>
          <div className="Upgrade-topsection">
            <div className="Upgrade-metric">
              <p>Total Queries</p>
              <div className="Upgrade-value">{queryTotal}</div>
              <div className="Upgrade-progress">
                <div
                  className="Upgrade-progress-bar"
                  style={{ width: `${(queryUsed / queryTotal) * 100}%` }}
                />
              </div>
              <div className="Upgrade-details">
                {queryUsed} Used of {queryTotal} queries.
              </div>

              <Link to="/Orderinfo">
                <button className="Upgrade-button">
                  <div>
                    <img
                      src="../../assets/Adminapp/upgradestar.png"
                      alt="star"
                      style={{ width: "18px" }}
                    ></img>
                  </div>
                  <div> Upgrade Now </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="Upgrade-section">
          <p className="Upgrade-heading">Transaction History</p>
          <table className="Upgrade-transaction-details">
            <thead>
              <tr className="Upgrade-tablerows">
                <th className="Upgrade-table-heading">Transaction No</th>
                <th className="Upgrade-table-heading">Date Paid</th>
                <th className="Upgrade-table-heading">Card Type</th>
                <th className="Upgrade-table-heading">Card Number</th>
                <th className="Upgrade-table-heading">Amount</th>
                <th className="Upgrade-table-heading">Status</th>
                <th className="Upgrade-table-heading">Receipts</th>
              </tr>
            </thead>
            <tbody>
              {transactionHistory.map((transaction) => (
                <tr
                  key={transaction.transactionNo}
                  className="Upgrade-tablerows"
                >
                  <td>{transaction.transactionNo}</td>
                  <td>{transaction.datePaid}</td>
                  <td>{transaction.cardType}</td>
                  <td>{transaction.cardNumber}</td>
                  <td>{transaction.amount}</td>
                  <td>
                    <span
                      className="Upgrade-status-icon"
                      style={{
                        backgroundColor:
                          transaction.status === "Paid" ? "green" : "red",
                      }}
                    />
                    {transaction.status}
                  </td>
                  <td>{transaction.receipts}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="Upgrade-right-container"></div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default Upgrade;
