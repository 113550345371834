import React, { useState, useEffect } from "react";
import { useContext } from "react";
import axios from "axios";
import "../../CSS_Files/Common/UploadDocuments.css";
import "../../App.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme.js";
import { useTheme, ThemeProvider } from "@mui/material";
import Modaldescription from "../../scenes/Modals/Modaldescription.jsx";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";
import Modaledit from "../../scenes/Modals/Modaledit.jsx";
import FileModal from "./FileViewerModal.js";
import TitleContext from "../UserPanel/TitleContext.js";
import Alert from "./Alertbox.js";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const UploadDocs = (props) => {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  console.log("title : ", title);
  const [file, setFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [opendesc, setOpendesc] = useState(false);

  const [loading, setLoading] = useState(true);

  const [allFiles, setAllFiles] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 7;
  const [name, setName] = useState("Profile");
  const [uploading, setUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [fileToEdit, setFileToEdit] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [documnetsLimit, setDocumnetsLimit] = useState();
  const [showAlertForDocuments, setShowAlertForDocuments] = useState(false);
  const { fileTypes } = useContext(TitleContext);
  console.log(fileTypes);

  const handleClickOpenDel = (file) => {
    console.log("Opening delete popup");
    setFileToDelete(file);
    setOpenDeletePopup(true);
  };
  const handleCloseDel = () => {
    console.log("CLosing delete popup");
    setOpenDeletePopup(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    axios
      .get(`${host}/auth/getuser`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setName(res.data.name);
        setDocumnetsLimit(res.data.docLimit);
        console.log("res.data : ", res.data);
      })
      .catch((err) => console.log(err));

    getFiles();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-bg-dark", colors.primaryBgDark);
    root.style.setProperty("--primary-bg-files", colors.primaryBgfiles);
    root.style.setProperty("--text-color", colors.textcol);
    root.style.setProperty("--rows-bg", colors.primaryBgDark);
    root.style.setProperty("--hover1", colors.hovergrad1);
    root.style.setProperty("--hover2", colors.hovergrad2);
  }, [colors]);

  const getFiles = async () => {
    const result = await axios.get(`${host}/get-files`, {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    });
    console.log(result.data.data);
    console.log(result.data.data.length);
    if (result.data.data.length >= documnetsLimit) {
      setShowAlertForDocuments(true);
    }
    setAllFiles(result.data.data);
  };

  const showFile = async (filename) => {
    console.log("In show file");
    console.log("filename", filename);
    let pathParts = filename.split("/").slice(5);
    let desiredPath = pathParts.join("/");
    console.log("desired path", desiredPath);

    const endpoint = `${host}/get-file-url`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ path: desiredPath }),
    });

    if (response.ok) {
      const fileUrl = await response.json();
      console.log(fileUrl);
      console.log(fileUrl.url);
      console.log("extension -> ", fileUrl.extension);
      setShowModal(true);
      setFileUrl(fileUrl.url);
      setFileExtension(fileUrl.extension);
    } else {
      console.log(response.error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFileUrl(null); 
    setFileExtension(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (selectedFile) {
      if (selectedFile.size > maxSize) {
        alert("File size exceeds 2 MB. Please choose a smaller file.");
        setSelectedFileName("");
        setFile("");
      } else {
        setSelectedFileName(selectedFile.name);
        setFile(selectedFile);
      }
    }
  };

  const handleUploadClick = () => {
    document.getElementById("uploadbtn").click();
    setShowPopup(true);
    console.log(showPopup);
  };

  const uploadFile = async (e, title) => {
    if (e) {
      e.preventDefault(); 
    } else {
      console.error("No event object found in uploadFile function."); 
      return;
    }
    if (title.length === 0) {
      alert("Please write file description");
    } else if (file.length !== 0) {
      console.log(title);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);
      console.log(file);
      setUploading(true);
      const endpoint = `${host}/upload`;
      await axios
        .post(endpoint, formData, {
          headers: {
            "Content-type": "multipart/form-data",
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setFile("");
          setTitle("");
          console.log("title : ", title);
          setSelectedFileName("");
          getFiles();
          navigate("/uploaddocs");
        })
        .catch((er) => console.log("error", er));
      getFiles();
    } else {
      alert("Please attach file");
    }
    setUploading(false);
  };

  // Delete a File
  const deleteFile = async (id) => {
    setIsDeleting(true);
    const response = await fetch(`${host}/deletefile/${id}`, {
      method: "DELETE",
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    console.log(json);
    const newFiles = allFiles.filter((file) => {
      return file._id !== id;
    });
    setAllFiles(newFiles);
    setIsDeleting(false);
  };

  const handleFormatClick = (format) => {
    if (selectedFormat === format) {
      setSelectedFormat("");
    } else {
      setSelectedFormat(format);
    }
  };
  const editFile = async (id, title) => {
    setIsUpdating(true);
    const response = await fetch(`${host}/updatefile/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ title }),
    });
    const json = await response.json();
    console.log(json);
    let newFiles = JSON.parse(JSON.stringify(allFiles));
    console.log("1");
    for (let index = 0; index < newFiles.length; index++) {
      const element = newFiles[index];
      console.log(element);
      console.log("2");
      if (element._id === id) {
        console.log(title);
        newFiles[index].title = title;
        break;
      }
    }
    setAllFiles(newFiles);
    setIsUpdating(false);
    // getFiles();
  };

  const updateFile = (file) => {
    if (file.pdf.endsWith(".pdf")) {
      setFileToEdit(file);
      setOpenUpdatePopup(true);
    } else {
      navigate(`/XLSXViewer/${file._id}`);
    }
  };

 
const renderBottomIcons = () => {
  const icons = {
    docx: "../../assets/docs.png",
    xlsx: "../../assets/excel.png",
    pptx: "../../assets/pptx.png",
    pdf: "../../assets/pdf.png",
    txt: "../../assets/text.png"
  };

  return (
    <div className="supported">
      {fileTypes.map((type) => (
        <div
          key={type}
          className="format cursor-pointer"
          onClick={() => handleFormatClick(type)}
        >
          <img
            src={icons[type]}
            alt={type}
          />
        </div>
      ))}
    </div>
  );
};


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    navigate(`/uploaddocs?page=${pageNumber}`);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = allFiles?.slice(indexOfFirstRecord, indexOfLastRecord);
  const filteredRecords = selectedFormat
    ? currentRecords.filter((file) => file.pdf.endsWith(`.${selectedFormat}`))
    : currentRecords;

  return (
    <ThemeProvider theme={theme}>
      <div className={`upload ${showModal ? "blur" : ""}`}>
        <div className="left-box"></div>
        <div className="docs">
          <div className="upload-doc">
            <div className="txt text-xl">Upload Documents</div>
            <input
              className="file"
              type="file"
              onChange={handleFileChange}
              id="uploadbtn"
              style={{ display: "none" }}
              accept={fileTypes.map((ext) => `.${ext}`).join(",")}
            />
            <div className="file-des">
              {selectedFileName && (
                <div className="file-name">{selectedFileName}</div>
              )}

              <div
                onClick={() => {
                  handleUploadClick();
                  setOpendesc(true);
                }}
                className="btn3 flex justify-between cursor-pointer"
              >
                <img src="../../assets/upload_btn.png" alt="not found"></img>
                <button> Upload File</button>
              </div>
            </div>
          </div>
          {selectedFileName && opendesc && (
            <Modaldescription
              open={opendesc}
              handleClose={() => {
                setOpendesc(false);
                setSelectedFileName(null);
              }}
              upload={uploadFile}
            />
          )}
          <Modaledit
            open={openUpdatePopup}
            handleClose={() => setOpenUpdatePopup(false)}
            editFile={editFile}
            file={fileToEdit}
          />
          {uploading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {isDeleting && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {isUpdating && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {currentRecords && (
            <div className="filecontainer">
              <table className="filetab">
                <thead>
                  <tr className="">
                    <th>File name</th>
                    <th>Description</th>
                    <th>
                      <div className="ml-5">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {filteredRecords.map((file) => (
                    <tr className="fileeach" key={file._id}>
                      <td className="w-[35%]">
                        <p className="" title={file.pdf.slice(13)}>
                          {file.filename}
                        </p>
                      </td>
                      <td className="w-[50%]">
                        <h5 className="" title={file.title}>
                          {file.title.slice(0, 75)}
                        </h5>
                      </td>
                      <td className="w-[10%]">
                        <button
                          onClick={() => {
                            updateFile(file);
                          }}
                          className="action-btn"
                        >
                          {" "}
                          <img
                            src="../../assets/Edit.png"
                            alt="not found"
                            className="mr-3"
                          ></img>{" "}
                        </button>

                        <button
                          onClick={() => {
                            showFile(file.pdf);
                          }}
                          className="action-btn"
                        >
                          <img
                            src="../../assets/Eye.png"
                            alt="not found"
                            className="mr-3"
                          ></img>
                        </button>
                        <button
                          onClick={() => {
                            handleClickOpenDel(file);
                          }}
                          className="action-btn"
                        >
                          {" "}
                          <img
                            src="../../assets/Delete.png"
                            className="del-icon"
                            alt="not found"
                          ></img>
                        </button>
                        <ModalDelete
                          open={openDeletePopup}
                          handleClose={handleCloseDel}
                          func={() => {
                            deleteFile(fileToDelete?._id);
                            handleCloseDel();
                          }}
                          text_mod={`Do you want to permanently delete ${fileToDelete?.filename}? `}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}{" "}
          {!currentRecords?.length &&
            currentPage > 1 &&
            handlePageChange(currentPage - 1)}
          <div className="bottomrow">{renderBottomIcons()}</div>
          {Math.ceil(allFiles?.length / recordsPerPage) > 1 && (
            <div className="pages">
              {" "}
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(allFiles?.length / recordsPerPage)}
                onPageChange={handlePageChange}
              />
            </div>
          )}
          {showModal && fileUrl && (
            <FileModal
              showModal={showModal}
              closeModal={closeModal}
              fileUrl={fileUrl}
              fileExtension={fileExtension}
            />
          )}
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {showAlertForDocuments && (
            <Alert
              message="Your Documents limit is exceeded! Please contact the organization"
              type="error"
              onClose={() => setShowAlertForDocuments(false)}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default UploadDocs;
