import React, { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const TitleContext = createContext();

export const TitleProvider = ({ children }) => {
  const [generatedTitle, setGeneratedTitle] = useState("");
  const [titleHistory, setTitleHistory] = useState([]); // History of titles
  const [fileTypes, setFileTypes] = useState([]); // Allowed file types
  const getConvRef = useRef(false);

  const config = require("../../config_frontend.js");
  const host = config.server.host[process.env.REACT_APP_HOST];
  let titles = {};

  const location = useLocation(); 


  useEffect(() => {
    axios
      .get(`${host}/auth/getuser`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data) {
          const fileTypesArray = res.data.docAllowed
            .map((id) => {
              switch (id) {
                case 0: return "pptx";
                case 1: return "docx";
                case 2: return "xlsx";
                case 3: return "pdf";
                case 4: return "txt";
                default: return undefined;
              }
            })
            .filter((type) => type !== undefined);
          setFileTypes(fileTypesArray);
        }
      })
      .catch((err) => console.error(err));
      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .get(`${host}/auth/getAdmin`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data) {
          const fileTypesArray = res.data.docAllowed
            .map((id) => {
              switch (id) {
                case 0: return "pptx";
                case 1: return "docx";
                case 2: return "xlsx";
                case 3: return "pdf";
                case 4: return "txt";
                default: return undefined;
              }
            })
            .filter((type) => type !== undefined);
          setFileTypes(fileTypesArray);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  // Fetch conversation titles from the server
  const getConv = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No auth token found");
      setTitleHistory([]);
      return;
    }

    try {
      const response = await axios.get(`${host}/chat/getUserConv`, {
        headers: {
          "auth-token": token,
        },
      });

      let allConvIDs = response.data || [];
      allConvIDs.reverse();

      // Update title history and titles object
      allConvIDs.forEach(async (conv) => {
        if (conv) {
          const title = conv.title;
          setTitleHistory((prevTitleHistory) => {
            const existingIndex = prevTitleHistory.findIndex(
              (item) => item.convId === conv._id
            );
            if (existingIndex !== -1) {
              const updatedHistory = [...prevTitleHistory];
              updatedHistory[existingIndex] = {
                title: conv.title,
                convId: conv._id,
              };
              return updatedHistory;
            } else {
              return [
                ...prevTitleHistory,
                { title: conv.title, convId: conv._id },
              ];
            }
          });

          titles[conv._id] = title;
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized: Invalid token or no token provided");
      } else {
        console.error("Error fetching conversations:", error);
      }
    }
  };

  // Fetch conversations when pathname changes
  useEffect(() => {
    if (!getConvRef.current) {
      getConvRef.current = true;
      getConv();
    } else {
      getConv();
    }
    // eslint-disable-next-line 
  }, [location.pathname]);

  // Fetch title for a specific conversation
  const gettingtitle = async (convId) => {
    if (!convId) {
      console.error("Invalid conversation ID");
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No auth token found");
      return;
    }

    try {
      const response = await fetch(`${host}/chat/getTitle/${convId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });

      const resp = await response.json();
      setGeneratedTitle(resp.title);

      setTitleHistory((prevTitleHistory) => {
        const existingIndex = prevTitleHistory.findIndex(
          (item) => item.convId === convId
        );
        if (existingIndex !== -1) {
          const updatedHistory = [...prevTitleHistory];
          updatedHistory.splice(existingIndex, 1);
          return [{ title: resp.title, convId }, ...updatedHistory];
        } else {
          return [{ title: resp.title, convId }, ...prevTitleHistory];
        }
      });

      titles[convId] = resp.title;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized: Invalid token or no token provided");
      } else {
        console.error("Error fetching title:", error);
      }
    }
  };

  useEffect(() => {
    console.log("Updated Generated Title:", generatedTitle);
  }, [generatedTitle]);

  return (
    <TitleContext.Provider
      value={{
        generatedTitle,
        setGeneratedTitle,
        titleHistory,
        setTitleHistory,
        gettingtitle,
        titles,
        getConv,
        fileTypes,
      }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export default TitleContext;
