// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPanel_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8%; 
  background-color: #040e22;
  color: white;
  border-bottom: 0.1px solid #2a354a;
}

.AdminPanel_account_details {
  display: flex;
  align-items: center;
}

.AdminPanel_account_image{
  border-radius: 50%;
  height: 37px;
  width: 37px;
  overflow: hidden;
  margin-right: 10px;
}

.AdminPanel_account_name{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.AdminPanel_account_email{
  font-size: 10px;
  opacity: 0.6;
}

.AdminPanel_header_ddown img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/AdminPanel/AdminTopBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".AdminPanel_header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  height: 8%; \r\n  background-color: #040e22;\r\n  color: white;\r\n  border-bottom: 0.1px solid #2a354a;\r\n}\r\n\r\n.AdminPanel_account_details {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.AdminPanel_account_image{\r\n  border-radius: 50%;\r\n  height: 37px;\r\n  width: 37px;\r\n  overflow: hidden;\r\n  margin-right: 10px;\r\n}\r\n\r\n.AdminPanel_account_name{\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: left;\r\n}\r\n\r\n.AdminPanel_account_email{\r\n  font-size: 10px;\r\n  opacity: 0.6;\r\n}\r\n\r\n.AdminPanel_header_ddown img {\r\n  height: 20px;\r\n  width: 20px;\r\n  margin-left: 10px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
