import React, { useState } from 'react';
import "./modal.css"
const Modaldescription = ({ open, handleClose, upload }) => {
    const [title, setTitle] = useState("");

    const handleTitleChange = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };

    const handleUpload = (e, title) => {
        upload(e, title);
        handleClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className='modalback'>
            <div className='modal-content-init'>
                <div className='flex justify-between'>
                    <div className='heading-modal'>Enter File Description</div>
                    <div className='flex justify-end mr-2 ' onClick={handleClose} >
                        <button><img src="../../assets/close.png" alt="" height={'10px'} width={'10px'} /></button>
                    </div>
                </div>
                <div className='flex justify-center inp-parent'>
                    <textarea
                        className="inp-modal"
                        placeholder="Enter title"
                        value={title}
                        onChange={(e) => handleTitleChange(e)}
                        required
                    ></textarea>


                </div>

                <button className="btn-modal-upload" onClick={(e) => { handleUpload(e, title) }}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default Modaldescription;
