import React, { useState } from "react";
import "../../CSS_Files/UserPanel/Settings.css";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const SettingsPage = () => {
  //To store generated SDK keys
  const [sdkKeys, setSdkKeys] = useState([]);
  
  // States for new key name and expiration date
  const [keyName, setKeyName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  //to manage dialog visibility for key generation
  const [showDialog, setShowDialog] = useState(false);

  //to manage delete functionality
  const [openDialog, setOpenDialog] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);

  //to generate API key
  const generateKey = () => {
    const characters = "QZ-ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const keyLength = 20;
    let key = "";
    for (let i = 0; i < keyLength; i++) {
      key += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return key;
  };

  //to show the key generation dialog
  const addKey = () => {
    setShowDialog(true);
  };

  //to handle form submission for key generation
  const handleSubmit = (e) => {
    e.preventDefault();
    const newKey = {
      key: generateKey(),
      name: keyName,
      expirationDate: expirationDate,
    };
    setSdkKeys([newKey, ...sdkKeys]);
    setKeyName(""); 
    setExpirationDate("");
    setShowDialog(false); 
  };

  //to show delete confirmation dialog
  const openDeleteDialog = (key) => {
    setKeyToDelete(key);
    setOpenDialog(true);
  };

  //to close the delete confirmation dialog
  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setKeyToDelete(null);
  };

  //to confirm and perform the key deletion
  const confirmDeleteQuery = () => {
    if (keyToDelete) {
      setSdkKeys(sdkKeys.filter((k) => k.key !== keyToDelete));
      setKeyToDelete(null);
      setOpenDialog(false);
    }
  };

  //to truncate a key for display
  const truncateKey = (key, length = 5) => {
    return key.substring(0, length) + "...";
  };

  //to copy a key to the clipboard
  const copyKey = (key) => {
    navigator.clipboard.writeText(key);
  };

  return (
    <div className="settings-page">
      <div className="settings-content">
        {sdkKeys.length === 0 ? (
          <div className="settings-empty-state">
            <p>Create an API key to access the app.</p>
            <button className="settings-generate-button-empty" onClick={addKey}>
              Generate New Key
            </button>
          </div>
        ) : (
          <div className="settings-container">
            <div className="settings-header">
              <p className="settings-title">SDK Keys</p>
              <button className="settings-generate-button" onClick={addKey}>
                Generate New Key
              </button>
            </div>
            <div className="settings-key-table-container">
              <table className="settings-key-table">
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Name</th>
                    <th>Expiration Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sdkKeys.map((sdkKey, index) => (
                    <tr key={index}>
                      <td>{truncateKey(sdkKey.key)}</td>
                      <td>{sdkKey.name}</td>
                      <td>{sdkKey.expirationDate}</td>
                      <td>
                        <div className="settings-button-container">
                          <button
                            onClick={() => copyKey(sdkKey.key)}
                            title="Copy Key"
                          >
                            <img src="../../assets/copy.png" alt="copy" />
                          </button>
                          <button
                            onClick={() => openDeleteDialog(sdkKey.key)}
                            title="Delete Key"
                          >
                            <img src="../../assets/Delete.png" alt="delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {/* Backdrop and loader for key generation */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(2px)",
        }}
        open={showDialog}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Dialog for key generation */}
      {showDialog && (
        <div className="settings-dialog-container">
          <div className="settings-dialog">
            <div className="settings-dialog-header">
              <p>Generate New Key</p>
              <button
                className="settings-dialog-close-button"
                onClick={() => setShowDialog(false)}
              >
                X
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <label>
                Key Name:
                <input
                  type="text"
                  value={keyName}
                  onChange={(e) => setKeyName(e.target.value)}
                  required
                />
              </label>
              <br />
              <label>
                Expiration Date:
                <input
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  required
                />
              </label>
              <br />
              <button type="submit">Generate Key</button>
            </form>
          </div>
        </div>
      )}

      {/* Modal for delete confirmation */}
      <ModalDelete
        open={openDialog}
        handleClose={closeDeleteDialog}
        func={confirmDeleteQuery}
        text_mod="Are you sure you want to delete this key? This action cannot be reversed."
      />
    </div>
  );
};

export default SettingsPage;
