// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ab:hover{
    background: linear-gradient(86.82deg, #E9380C 7.47%, #FFAD32 111.5%);

} 

.header-profile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.header-right{
    display: flex;
    justify-content: end;
    width: 50%;
}

.header-right-name{
    display:flex;
    justify-content: space-between;
    cursor:pointer;
    align-items: center; 
}

.header-ddown{
    padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/UserPanel/Topbar.css"],"names":[],"mappings":"AAAA;IACI,oEAAoE;;AAExE;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".ab:hover{\n    background: linear-gradient(86.82deg, #E9380C 7.47%, #FFAD32 111.5%);\n\n} \n\n.header-profile{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n}\n\n.header-right{\n    display: flex;\n    justify-content: end;\n    width: 50%;\n}\n\n.header-right-name{\n    display:flex;\n    justify-content: space-between;\n    cursor:pointer;\n    align-items: center; \n}\n\n.header-ddown{\n    padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
