// Modaledit.jsx
import React, { useState,useEffect } from 'react';
import "./modal.css";

const Modaledit = ({ open, handleClose, editFile, file }) => {
  const [title, setTitle] = useState(file ? file.title : "");
  useEffect(() => {
    if (file) {
      setTitle(file.title);
    }
  }, [file]);
  const handleTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if(title.trim()!==''){
    editFile(file._id, title);
    handleClose();}
    else{
        alert('Cannot be empty');
    }
  };

  if (!open) {
    return null;
  }

  return (
    <div className='modalback'>
      <div className='modal-content-init'>
        <div className='flex justify-between'>
          <div className='heading-modal'>Edit File Description</div>
          <div className='flex justify-end mr-2 ' onClick={handleClose} >
            <button><img src="../../assets/close.png" alt="" height={'10px'} width={'10px'} /></button>
          </div>
        </div>
        <div className='flex justify-center inp-parent'>
          <textarea
            className="inp-modal"
            placeholder="Enter title"
            value={title}
            onChange={handleTitleChange}
            required
          ></textarea>
        </div>
        <button className="btn-modal-upload" onClick={handleEdit}>
          Update
        </button>
      </div>
    </div>
  );
};

export default Modaledit;
