import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../CSS_Files/UserPanel/Login.css";
import "../../App.css";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Login = (props) => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailissue, setemailissue] = useState(false);
  const [passissue, setpassissue] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = credentials;
    if (!email || !password) {
      if (!email) {
        setemailissue(true);
      }
      if (!password) {
        setpassissue(true);
      }
      // props.showAlert('Please enter both email and password', 'danger');
      alert("Please enter both email and password");
      return;
    }
    const response = await fetch(`${host}/auth/adminlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    const json = await response.json();
    console.log(json);
    if (json.success) {
      // Save the auth token and redirect to the Favouritecharts page
      console.log(json.authToken);
      localStorage.setItem("token", json.authToken);
      // props.showAlert("Logged in successfully", 'success');
      // alert('box-background');

      navigate("/dashboard"); // Navigate to the newchat page
      // alert("Logged in successfully")
    } else {
      // props.showAlert("Invalid credentials", 'danger')
      if (json.msg && json.msg === "no access") {
        alert(
          "Please wait while we grant you access."
        );
      } else {
        alert("Invalid credentials");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setemailissue(false);
    } else if (e.target.name === "password") {
      setpassissue(false);
    }
  };

  // const googleAuth = () =>{
  //     window.open(`${host}/auth/google/callback`, '_self');
  // }

  // const login = useGoogleLogin({
  //   onSuccess: async (response) => {
  //     try {
  //       // const res = await axios.get(
  //       //     "https://www.googleapis.com/oauth2/v3/userinfo",
  //       //     {
  //       //         headers: {
  //       //             Authorization: `Bearer ${response.access_token}`
  //       //         }
  //       //     }
  //       // )
  //       // console.log(res);
  //       // console.log(res.data);
  //       // const email = res.data.email;
  //       console.log("Access token -> ", response.access_token);
  //       const resp = await fetch(`${host}/auth/glogin`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ access_token: response.access_token }),
  //       });
  //       const json = await resp.json();
  //       console.log(json);
  //       if (json.success) {
  //         // Save the auth token and redirect
  //         console.log(json.authToken);
  //         localStorage.setItem("token", json.authToken);
  //         // props.showAlert("Logged in successfully", 'success');
  //         navigate("/dashboard");
  //         // alert("Logged in successfully")
  //       } else {
  //         // props.showAlert("Invalid credentials", 'danger')
  //         if (json.msg && json.msg === "no access") {
  //           alert(
  //             "Sorry! You're not an authorised user for this platform. Please ask your admin to give you access."
  //           );
  //         } else {
  //           alert("Invalid credentials");
  //         }
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  // });

  return (
    <>
      <div className="login-page-first-page">
        <div className="login-box-first-page">
          <div className="logo-first-page">
            <img alt="logo" src="../../assets/Logo-Light.png"></img>
            <div
              style={{
                fontWeight: "600",
                fontSize: "16px",
                marginLeft: "33px",
                marginTop: "20px",
              }}
            >
              Admin Login
            </div>
          </div>

          <div className="no-account-first-page">
            <div className="dont-account-first-page">
              Don't have an account?
            </div>
            <div className="sign-up-first-page">
              <Link id="signup" to="/adminsignup">
                Sign Up
              </Link>
            </div>
          </div>

          <div className="input-fields-first-page">
            <div>
              <label className="email-first-page">Email</label>
            </div>
            <div>
              <input
                type="email"
                className="email-input-first-page"
                id="email"
                name="email"
                value={credentials.email}
                onChange={onChange}
                placeholder="Enter your email"
              ></input>
            </div>
            <div className={emailissue ? "error-msg1-first-page" : " hidden"}>
              Fill in email
            </div>

            <div className="password-first-page">
              <label className="pass-first-page">Password</label>
              <label className="forgot-pass-first-page">Forgot password?</label>
            </div>

            <div className="forgotpassword-first-page">
              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  className="pass-input-first-page"
                  id="password"
                  name="password"
                  value={credentials.password}
                  onChange={onChange}
                  placeholder="Enter your password"
                ></input>
              </div>
              <div>
                <img
                  className="pass-input-img-first-page"
                  src={"../../assets/view.png"}
                  alt="View"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>

            <div
              className={
                passissue
                  ? "error-msg2-first-page"
                  : "error-msg2-first-page hidden"
              }
            >
              Fill in password
            </div>
          </div>

          <button className="sign-in-first-page" onClick={handleSubmit}>
            Sign In &rarr;
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
