// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPanel_sidebar_container {
  display: flex;
  flex-direction: column;
  background-color: #020516;
  width: 15%;
  height: 100vh;
}

.AdminPanel_sidebar_logo {
  margin-top: 8%;
  margin-left: 6%;
}

.AdminPanel_sidebar_mainfields {
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  gap: 5px;
  font-size: 18px;
}

.AdminPanel_sidebar_dashboard,
.AdminPanel_sidebar_users,
.AdminPanel_sidebar_documents,
.AdminPanel_sidebar_database,
.AdminPanel_sidebar_upgrade,
.AdminPanel_sidebar_help,
.AdminPanel_sidebar_theme,
.AdminPanel_sidebar_setting {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.AdminPanel_sidebar_mainfields .selectedsidebaricon {
  background-color: #212332;
  color: #E83C07;
  border-left: 5px solid #E83C07;
}

.AdminPanel_sidebar_bottomicons {
  margin-top: 17rem;
  margin-left: 6%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 18px;
}

.AdminPanel_sidebar_mainfields > div,
.AdminPanel_sidebar_bottomicons > div {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/CSS_Files/AdminPanel/AdminSidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,QAAQ;EACR,eAAe;AACjB;;AAEA;;;;;;;;EAQE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB","sourcesContent":[".AdminPanel_sidebar_container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: #020516;\r\n  width: 15%;\r\n  height: 100vh;\r\n}\r\n\r\n.AdminPanel_sidebar_logo {\r\n  margin-top: 8%;\r\n  margin-left: 6%;\r\n}\r\n\r\n.AdminPanel_sidebar_mainfields {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-top: 8%;\r\n  gap: 5px;\r\n  font-size: 18px;\r\n}\r\n\r\n.AdminPanel_sidebar_dashboard,\r\n.AdminPanel_sidebar_users,\r\n.AdminPanel_sidebar_documents,\r\n.AdminPanel_sidebar_database,\r\n.AdminPanel_sidebar_upgrade,\r\n.AdminPanel_sidebar_help,\r\n.AdminPanel_sidebar_theme,\r\n.AdminPanel_sidebar_setting {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 15px;\r\n  color: #ffffff;\r\n  padding: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.AdminPanel_sidebar_mainfields .selectedsidebaricon {\r\n  background-color: #212332;\r\n  color: #E83C07;\r\n  border-left: 5px solid #E83C07;\r\n}\r\n\r\n.AdminPanel_sidebar_bottomicons {\r\n  margin-top: 17rem;\r\n  margin-left: 6%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 5px;\r\n  font-size: 18px;\r\n}\r\n\r\n.AdminPanel_sidebar_mainfields > div,\r\n.AdminPanel_sidebar_bottomicons > div {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
