import React from "react";
import "../../CSS_Files/Common/Pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="page-navi" >
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            id="navi"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <i className="fa-solid fa-angle-left" style={{color: "darkslategrey"}}></i>
          </button>
        </li>
        {[...Array(totalPages)].map((_, index) => (
          <li
            key={`page-${index + 1}`} 
            className="page-item"
          >
            <button
              className={`page-link ${index + 1 === currentPage ? 'current-page' : ''}`}
              id="navi"
              onClick={() => onPageChange(index + 1)}
            >
              <p style={{color: "darkslategrey"}}>{index + 1}</p>
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            id="navi"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <i className="fa-solid fa-angle-right" style={{color: "darkslategrey"}}></i>
          </button>
        </li>
      </ul>
    </div>
  );
};
export default Pagination;
