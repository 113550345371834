import React, { useEffect, useState } from "react";
import { Typography, Backdrop, CircularProgress } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import "../../CSS_Files/UserPanel/favquer.css";
import config from "../../config_frontend.js";
import ModalDelete from "../../scenes/Modals/Modal_delete.jsx";

const host = config.server.host[process.env.REACT_APP_HOST];

const FavouriteQueries = () => {
  const [favQueries, setFavQueries] = useState([]); //to store favorite queries
  const [openDialog, setOpenDialog] = useState(false); //to manage delete dialog visibility
  const [queryToDelete, setQueryToDelete] = useState(null); //to store the query to be deleted
  const [loading, setLoading] = useState(true); //to manage loader visibility
  const [fullTextDialog, setFullTextDialog] = useState({
    open: false,
    text: "",
  }); //to manage full text dialog visibility and content
  const [backdropOpen, setBackdropOpen] = useState(false); //to manage backdrop visibility

  // Fetch favorite queries when the component is mounted
  useEffect(() => {
    getFavQueries();
  }, []);

  //to fetch favorite queries from the server
  const getFavQueries = async () => {
    try {
      const type = "text";
      const response = await axios.get(`${host}/chat/getFav/${type}`, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setFavQueries(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching favorite queries:", error);
      setLoading(false);
    }
  };

  //to open the delete dialog
  const openDeleteDialog = (query) => {
    setQueryToDelete(query);
    setOpenDialog(true);
  };

  //to close the delete dialog
  const closeDeleteDialog = () => {
    setOpenDialog(false);
  };

  //to confirm and delete a query
  const confirmDeleteQuery = async () => {
    setLoading(true);
    try {
      await axios.put(
        `${host}/chat/unpin`,
        { msg: queryToDelete },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      getFavQueries();
    } catch (error) {
      console.error("Error deleting query:", error);
    } finally {
      closeDeleteDialog();
      setLoading(false);
    }
  };

  //To truncate text to a specified word limit and add a "more" indicator
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return (
        <>
          {words.slice(0, wordLimit).join(" ")}
          <span style={{ color: "gray", cursor: "pointer" }}>..more</span>
        </>
      );
    }
    return text;
  };

  //To open the full text dialog
  const openFullTextDialog = (text) => {
    setFullTextDialog({ open: true, text });
    setBackdropOpen(true);
  };

  //to close the full text dialog
  const closeFullTextDialog = () => {
    setFullTextDialog({ open: false, text: "" });
    setBackdropOpen(false);
  };

  return (
    <div>
      <div className="header-queries">Favourite Queries</div>
      <div className="queryContainer">
        {favQueries.map((queries, index) => (
          <div
            className="messagePairContainer"
            key={index}
            onClick={() => openFullTextDialog(queries)}
          >
            <div className="topMessage queries">{queries.title}</div>
            <div className="bottomMessage queries">
              <Typography className="queryCss" style={{ whiteSpace: "break-spaces" }}>
                {truncateText(queries.content, 10)}
              </Typography>
            </div>
            <div>
              <button
                className="deleteButton"
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteDialog(queries);
                }}
              >
                <img src="../../assets/pinstraight.png" alt="Unpin" />
              </button>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ModalDelete
        open={openDialog}
        handleClose={closeDeleteDialog}
        func={confirmDeleteQuery}
        text_mod="Wish to unpin this query? This action cannot be reversed."
      />

      {fullTextDialog.open && (
        <Dialog
          open={fullTextDialog.open}
          onClose={closeFullTextDialog}
          PaperProps={{
            style: {
              borderRadius: "25px",
              padding: "20px",
              backgroundColor: "#040e22",
            },
          }}
        >
          <DialogContent
            style={{ padding: "5px", borderRadius: "25px", overflow: "hidden" }}
          >
            <Typography className="dialogboxtitle">
              {fullTextDialog.text.title}
            </Typography>
            <Typography
              className="dialogboxcontent"
              style={{ whiteSpace: "break-spaces" }}
            >
              {fullTextDialog.text.content}
            </Typography>
          </DialogContent>
        </Dialog>
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(1px)",
        }}
        open={backdropOpen}
      />
    </div>
  );
};

export default FavouriteQueries;
