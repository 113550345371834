import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../CSS_Files/Common/DatabaseDef.css";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
//import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "./Pagination";
const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const DatabaseDetailsPage = () => {
  const id = useParams().id;
  const navigate = useNavigate();
  console.log("Params Id -> ", id);
  const [selectedTable, setSelectedTable] = useState(null);
  // eslint-disable-next-line
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [columnDescriptions, setColumnDescriptions] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [schema, setSchema] = useState(null);
  const [uploadingdetails, setUploadingdetails] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expandedColumn, setExpandedColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tablesPerPage = 9;
  // const { location } = props;
  // const { state } = location;
  // const { state } = useNavigate();
  // const location = useLocation();
  // const data = location.state;

  //   const func = async() =>{
  //     const result = await fetch(`${host}:${port}/connectdb/getschema/${id}`, {
  //       method: 'GET',
  //       headers: {
  //         "auth-token": localStorage.getItem("token"),
  //       },
  //     })
  //     const res = await result.json();
  //     console.log(res);
  //     console.log("Schema->", res.schema);
  //     setSchema(res.schema);
  //   }

  //   useEffect(() => {

  //     func();

  // }, []);

  useEffect(() => {
    const func = async () => {
      try {
        const result = await fetch(`${host}/connectdb/getschema/${id}`, {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        });
        const res = await result.json();
        console.log("Schema->", res.schema);
        setSchema(res.schema);
      } catch (error) {
        console.error("Error fetching schema:", error);
      }
    };

    func();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-bg-dark", colors.primaryBgDark);
    root.style.setProperty("--primary-bg-files", colors.primaryBgfiles);
    root.style.setProperty("--text-color", colors.textcol);
    root.style.setProperty("--chatbackground", colors.chatbg);
    root.style.setProperty("--chatbox", colors.box);
    root.style.setProperty("--dbback", colors.dbbg);
  }, [colors]);

  const handleGoBack = () => {
    navigate("/connectdb");
  };

  const toggleSidebar = (table) => {
    if (selectedTable && selectedTable.name === table) {
      setSidebarOpen(false);
      setSelectedTable(null);
    } else {
      let columns = [];
      for (const key in schema[table].columns) {
        columns.push(key);
      }
      let tableObj = {
        name: table,
        columns,
      };
      setSelectedTable(tableObj);
      setSidebarOpen(true);
    }
  };

  const handleColumnClick = (column) => {
    if (expandedColumn === column) {
      setExpandedColumn(null);
    } else {
      setExpandedColumn(column);
    }
  };
  const handleDescriptionChange = (e) => {
    setSchema((prevSchema) => ({
      ...prevSchema,
      dbDef: e.target.value,
    }));
  };

  const handleDescription = (e, table, col) => {
    const { value } = e.target;
    schema[table].columns[col] = value;
    setColumnDescriptions({
      ...columnDescriptions,
      [selectedColumn]: value,
    });
  };

  const handleSave = async () => {
    if (schema.dbDef.trim() === "") {
      alert("Necesaary to add db details");
      return;
    }
    console.log("Changes saved!");
    console.log(schema);
    console.log("storing the updated schema in MongoDB");
    setUploadingdetails(true);
    await fetch(`${host}/connectdb/storeschema/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ schema }),
    })
      .then((resp) => {
        console.log(resp);
        alert("Changes saved successfully");
      })
      .catch((err) => console.log(err));
    setUploadingdetails(false);
  };
  const startIndex = (currentPage - 1) * tablesPerPage;
  const endIndex = startIndex + tablesPerPage;
  const currentTables = schema
    ? Object.keys(schema)
        .filter((table) => table !== "dbDef" && table !== "dbName")
        .slice(startIndex, endIndex)
    : [];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTextareaClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="databasedef-container">
      <div className="databasedef-container-left-container"></div>
      <div className="databasedef-container-middle-container">
        <div className="header-databasedef">
          <div className="main-heading-databasedef">
            <div onClick={handleGoBack}>
              <img src="../../assets/backarrow.png" alt="backarrow"></img>
            </div>
            <div>
              <h1>{schema && schema.dbName}</h1>
            </div>
          </div>
          <button>
            {" "}
            <div className="Save-btn-databasedef" onClick={handleSave}>
              <div>
                <img src="../../assets/Save.png" alt="Save" />
              </div>
              <div>Save</div>
            </div>
          </button>
        </div>

        <div className="section-databasedef">
          {schema && (
            <div>
              <div className="section-databasedef-dbheading">
                Database Description
              </div>
              <div>
                <textarea
                  className="section-databasedef-description"
                  value={schema.dbDef}
                  rows={2}
                  cols={48}
                  placeholder={`Enter description for database`}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
          )}

          <hr />

          <p className="headingcols-databasedef">Tables</p>
          <ul>
            <div className="table-container-databasedef">
              {currentTables.map((table, idx) => (
                <div
                  key={idx}
                  className={`tabledetails-databasedef ${
                    selectedTable && selectedTable.name === table
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => toggleSidebar(table)}
                >
                  <div>
                    <img
                      src={
                        selectedTable && selectedTable.name === table
                          ? "../../assets/sidearrow_selected.png"
                          : "../../assets/sidearrow.png"
                      }
                      alt="Arrow"
                    />
                  </div>
                  <div className="contenttab-databasedef">{table}</div>
                </div>
              ))}
            </div>
          </ul>
        </div>

        {/* Render Pagination Component */}
        {schema &&
          Object.keys(schema).filter(
            (table) => table !== "dbDef" && table !== "dbName"
          ).length > tablesPerPage && (
            <div className="pages">
              {" "}
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  Object.keys(schema).filter(
                    (table) => table !== "dbDef" && table !== "dbName"
                  ).length / tablesPerPage
                )}
                onPageChange={handlePageChange}
              />
            </div>
          )}

        {/* Sidebar */}
        {uploadingdetails && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <div
          className={`sidebar-databasedef ${
            selectedTable && sidebarOpen && "open"
          }`}
        >
          {selectedTable && (
            <>
              <div className="top-section">
                <h2>{selectedTable.name}</h2>
                <img
                  src="../../assets/close.png"
                  height={15}
                  width={15}
                  className={`close-button ${
                    isHovered ? "cursor-pointer" : ""
                  }`}
                  alt="Close"
                  onClick={() => {
                    setSidebarOpen(false);
                    setSelectedTable(null);
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </div>

              <hr />

              <div className="definition">
                <h3>Definition</h3>
                <textarea
                  className="des"
                  value={schema && schema[selectedTable.name].definition}
                  rows={2}
                  cols={60}
                  placeholder={`Describe your ${selectedTable.name} details`}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSchema((prevSchema) => ({
                      ...prevSchema,
                      [selectedTable.name]: {
                        ...prevSchema[selectedTable.name],
                        definition: value,
                      },
                    }));
                  }}
                />
              </div>

              <div className="details">
                {selectedTable.columns.map((column) => (
                  <div
                    key={column}
                    className="column"
                    onClick={() => handleColumnClick(column)}
                  >
                    <div className="namee">
                      <div className="sidearrow-sidebar">
                        <img
                          src={
                            expandedColumn === column
                              ? "../../assets/sidebar-down.png"
                              : "../../assets/sidebar-left.png"
                          }
                          alt="Arrow"
                          style={{ width: "10px", height: "10px" }}
                        />
                      </div>
                      <div>{column}</div>
                    </div>
                    {expandedColumn === column && (
                      <textarea
                        className="des"
                        value={
                          schema && schema[selectedTable.name].columns[column]
                        }
                        rows={3}
                        cols={50}
                        placeholder={`Enter description for ${column}`}
                        onChange={(e) =>
                          handleDescription(e, selectedTable.name, column)
                        }
                        onClick={handleTextareaClick}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="databasedef-container-right-container"></div>
    </div>
  );
};

export default DatabaseDetailsPage;
