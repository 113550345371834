import React from 'react';
import "./modal_delete.css"
const Modal_delete = ({ open, handleClose, func, text_mod }) => {

    // const handleUpload = (e, title) => {
    //     upload(e, title);
    //     handleClose();
    // };

    if (!open) {
        return null;
    }

    return (
        <div className='modalback'>
            <div className='modal-content-del'>
                <div className='flex justify-center h-[25%]'>
                    <div className='heading-modal-del'>Are you sure?</div>
                </div>
                <div className='flex justify-center self-center h-[35%] p-1 mt-3  text-base prompt ml-3 mr-3 '>
                    {text_mod}
                </div>
                <div className='flex justify-center h-[30%]'>      
                <button className=" w-[25%] h-[65%] p-0.5 btn-del radius-lg" onClick={handleClose}>
                    Cancel
                </button> 
                <button className="btn-modal-del" onClick={func }>
                Yes
                </button></div>
            </div>
        </div>
    );
};

export default Modal_delete;
