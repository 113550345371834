import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../CSS_Files/Common/XLSXViewer.css";
import { tokens } from "../../theme.js";
import { useTheme } from "@mui/material";
import Pagination from "./Pagination";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const XLSXViewer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [schema, setSchema] = useState(null);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tablesPerPage] = useState(4);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const result = await fetch(`${host}/getschema/${id}`, {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        });
        const res = await result.json();
        console.log(res.schema);
        setSchema(res.schema);
      } catch (error) {
        console.error("Error fetching schema:", error);
      }
    };
    fetchSchema();
  }, [id]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-bg-dark", colors.primaryBgDark);
    root.style.setProperty("--primary-bg-files", colors.primaryBgfiles);
    root.style.setProperty("--text-color", colors.textcol);
    root.style.setProperty("--chatbackground", colors.chatbg);
    root.style.setProperty("--chatbox", colors.box);
    root.style.setProperty("--dbback", colors.dbbg);
  }, [colors]);

  const handleExcelDescriptionChange = (event) => {
    const newValue = event.target.value;
    setSchema((prevSchema) => ({
      ...prevSchema,
      excelDesc: newValue,
    }));
  };

  const handleSheetDescriptionChange = (event) => {
    const newValue = event.target.value;
    setSchema((prevSchema) => ({
      ...prevSchema,
      [selectedSheet]: {
        ...prevSchema[selectedSheet],
        description: newValue,
      },
    }));
  };

  const handleColumnDescriptionChange = (table, column, e) => {
    const { value } = e.target;
    console.log("Changing description for column:", table, column, value);
    setSchema((prevSchema) => ({
      ...prevSchema,
      [selectedSheet]: {
        ...prevSchema[selectedSheet],
        tables: {
          ...prevSchema[selectedSheet].tables,
          [table]: {
            ...prevSchema[selectedSheet].tables[table],
            [column]: value,
          },
        },
      },
    }));
  };

  const saveDescriptions = async () => {
    try {
      const result = await fetch(`${host}/storeschema/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ schema }),
      });
      if (!result.ok) {
        throw new Error("Failed to save schema");
      }
      alert("Descriptions saved successfully!");
    } catch (error) {
      console.error("Error saving schema:", error);
      alert("Failed to save descriptions. Please try again.");
    }
  };

  const handleGoBack = () => {
    navigate("/uploaddocs");
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleTableClick = (tableName) => {
    setSelectedTable((prevSelectedTable) =>
      prevSelectedTable === tableName ? null : tableName
    );
  };

  const handleColumnClick = (columnName) => {
    setSelectedColumn((prevSelectedColumn) =>
      prevSelectedColumn === columnName ? null : columnName
    );
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastTable = currentPage * tablesPerPage;
  const indexOfFirstTable = indexOfLastTable - tablesPerPage;
  const currentTables = schema
    ? Object.keys(schema).filter(
        (table) => table !== "excelDesc"
      ).slice(indexOfFirstTable, indexOfLastTable)
    : [];

  return (
    <div>
      <div className="excelcss">
        <div className="left-container-excel"></div>
        <div className="middle-container-excel">
          <div className="header-excel">
            <div className="main-heading-excel">
              <div onClick={handleGoBack}>
                <img src="../../assets/backarrow.png" alt="backarrow" />
              </div>
              <div>
                <h1>Excel details</h1>
              </div>
            </div>
            <button onClick={saveDescriptions}>
              <div className="Save-btn-excel">
                <div>
                  <img src="../../assets/Save.png" alt="Save" />
                </div>
                <div>Save</div>
              </div>
            </button>
          </div>

          <div className="section-excel">
            {schema && (
              <div>
                <div className="excelheading">Excel Description</div>
                <div>
                  <textarea
                    className="exceldescription"
                    value={schema.excelDesc || ""}
                    rows={2}
                    cols={48}
                    placeholder={`Enter description for database`}
                    onChange={handleExcelDescriptionChange}
                  />
                </div>
              </div>
            )}

            <hr />

            <h2 className="headingexcel">Sheets</h2>
            <ul>
              <div className="table-container-excel">
                {currentTables.map((sheet, idx) => (
                  <div
                    key={idx}
                    className={`exceltabledetails ${
                      selectedSheet === sheet && sidebarOpen ? "selectedtable" : ""
                    }`}
                    onClick={() => {
                      setSelectedSheet(sheet);
                      setSidebarOpen(true);
                    }}
                  >
                    <div>
                      <img src="../../assets/sidearrow.png" alt="Arrow" />
                    </div>
                    <div className="excelcontenttab">{sheet}</div>
                  </div>
                ))}
              </div>
            </ul>
          </div>
          {schema &&
        Object.keys(schema).filter(
          (table) => table !== "excelDesc"
        ).length > tablesPerPage && (
          <div className="pages">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                Object.keys(schema).filter(
                  (table) => table !== "excelDesc"
                ).length / tablesPerPage
              )}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        </div>
        <div className="right-container-excel"></div>
      </div>

      {/* Sidebar */}
      <div className={`sidebar ${selectedSheet && sidebarOpen ? "open" : ""}`}>
        {selectedSheet && (
          <>
            <div className="top-section">
              <h2>{selectedSheet}</h2>
              <img
                src="../../assets/close.png"
                height={15}
                width={15}
                className={`close-button`}
                alt="Close"
                onClick={toggleSidebar}
              />
            </div>

            <hr />

            <div className="excelheading">Sheet Description</div>
            <div>
              <textarea
                className="sheetdescription"
                value={schema[selectedSheet]?.description || ""}
                rows={2}
                cols={48}
                placeholder={`Enter description for sheet`}
                onChange={handleSheetDescriptionChange}
              />
            </div>

            <div className="details">
              {schema[selectedSheet]?.tables &&
                Object.keys(schema[selectedSheet].tables).map((table) => (
                  <div key={table}>
                    <div className="tabless">
                      <div className="tabless-name" onClick={() => handleTableClick(table)}>
                        <div>
                          <img
                            src={selectedTable === table ? "../../assets/sidebar-down.png" : "../../assets/sidebar-left.png"}
                            alt="Arrow"
                            style={{ width: "10px", height: "10px" }}
                          />
                        </div>
                        <div style={{ fontSize: "18px" }}>{table}</div>
                      </div>
                      <ul>
                        {selectedTable === table &&
                          Object.keys(schema[selectedSheet].tables[table]).map((column) => (
                            <li key={column} className="column-description">
                              <div className="namee" onClick={() => handleColumnClick(column)}>
                                <div>
                                  <img
                                    src={selectedColumn === column ? "../../assets/sidebar-down.png" : "../../assets/sidebar-left.png"}
                                    alt="Arrow"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                </div>
                                <div style={{ fontSize: "16px" }}>{column}</div>
                              </div>
                              {selectedColumn === column && (
                                <textarea
                                  className="des"
                                  value={schema[selectedSheet].tables[table][column] || ""}
                                  rows={2}
                                  cols={50}
                                  placeholder={`Enter description for ${column}`}
                                  onChange={(e) => handleColumnDescriptionChange(table, column, e)}
                                />
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      
    </div>
  );
};

export default XLSXViewer;
